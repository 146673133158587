import Vuex from "vuex";
// import VuexPersistence from 'vuex-persist';
// import Crypto from 'crypto-js';
import Cookie from 'js-cookie';
//import uuid from 'uuid';
// import { v4 as uuidv4 } from 'uuid';

import auth from "../modules/auth/store/index";
import dashboard from "../modules/dashboard/store/index";
import global from "../store/global/index";
import management from "../modules/management/store/management/index";
import production from '../modules/production/store/index';
import documentation from '../modules/documentation-center/store/index';
import settlement from '../modules/settlement-billing/store/index';
import businesspartners from '../modules/business-partners/store/index';

const cookieName = 'cookieSIAC';

const storageKey = 'storageSIAC';
// const encryptionToken = Cookie.get(cookieName) || uuidv4();
// Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 });

// const vuexLocal = new VuexPersistence({
//   storage: {
//     getItem: () => {
//       // Get the store from local storage.
//       const store = window.localStorage.getItem(storageKey);

//       if (store) {
//         try {
//           // Decrypt the store retrieved from local storage
//           // using our encryption token stored in cookies.
//           const bytes = Crypto.AES.decrypt(store, encryptionToken);

//           return JSON.parse(bytes.toString(Crypto.enc.Utf8));
//         } catch (e) {
//           // The store will be reset if decryption fails.
//           window.localStorage.removeItem(storageKey);
//         }
//       }

//       return null;
//     },
//     setItem: (key, value) => {
//       // Encrypt the store using our encryption token stored in cookies.
//       const store = Crypto.AES.encrypt(value, encryptionToken).toString();

//       // Save the encrypted store in local storage.
//       return window.localStorage.setItem(storageKey, store);
//     },
//     removeItem: () => window.localStorage.removeItem(storageKey),
//   },
// });

const store = new Vuex.Store({
  // plugins: [vuexLocal.plugin],
  modules: {
    auth,
    dashboard,
    global,
    management,
    production,
    settlement,
    documentation,
    businesspartners,
  }
});
// Create a new store instance.
// const store = createStore({
//   modules: {
//     auth,
//     dashboard,
//     global,
//     management,
//     production,
//     settlement
//   }
// });

export default store;
