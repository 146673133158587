export default {
    code: 0,
    subAreaId: '',
    areaId: '',
    nameArea: '',
    nameSubArea: '',
    name: '',
    description: '',
    estimatedTime: 0,
    averagingTimeUsed: 0,
    hoursTradeService: 0,
    timesLastPeriod: [
        {
            year: 0,
            month: 0,
            value: 0,
        },
    ],
    changeRange: {
        firstEndHour: 0,
        lastEndHour: 0,
    },
    requiredDocumments: false,
    requiredRevision: false,
    active: true,
    isExclusiveWorkflows: false,
    isAppointmentSchedule: false,
    isBillable: false,
    stage: '',
    timeAfterActivityEnd: 0,
    timeNoAssignedNextActivity: 0,
    subtopic: [],
    companyId: ''
};
