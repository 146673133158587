export const updateGridCompanies = (state, getters, rootState) => {
    const newCompanies = state.companies?.map((company) => {
        //Name cities and countries
        rootState.global.company?.countries?.find((country) => {
            if (country.idCountry === company.generalInfo.country) {
                company.generalInfo.country = country.nameCountry;
                country.citys.find((city) => {
                    if (city.idCity === company.generalInfo.city) {
                        company.generalInfo.city = city.nameCity;
                    }
                });
            }
        });
        if (
            company.generalInfo.styleCompany.logo !== '' &&
            !company.generalInfo.styleCompany.logo.includes('data:image/png')
        ) {
            company.generalInfo.styleCompany.logo = `data:image/png;base64,${company.generalInfo.styleCompany.logo}`;
        }
        let editCompany = null;

        editCompany = rootState.global.userCompanies.find(
            (userCompany) => userCompany.companyId === company.id,
        );
        company.edit = editCompany !== undefined ? true : false;
        return company;
    });
    return newCompanies;
};

export const updateClientsByContacts = (state, getters, rootState) => {
    const newClient = state.clientsByContact?.map((client) => {
        rootState.global.company?.countries?.find((country) => {
            if (country.idCountry === client.country) {
                client.country = country.nameCountry;
                country.citys.find((city) => {
                    if (city.idCity === client.mainCity) {
                        client.mainCity = city.nameCity;
                    }
                });
            }
        });
        return client;
    });
    return newClient;
};

export const updateGridUsers = (state, getters, rootState) => {
    const newUsers = state.users?.map((user) => {
        rootState.management.companies?.find((company) => {
            if (company.id === user.associatedCompany) {
                user.associatedCompanyId = user.associatedCompany;
                user.associatedCompany = company.name;
            }
        });
        rootState.management.categories?.find((category) => {
            if (category.id === user.category) {
                user.categoryNom = user.category;
                user.category = category.name;
            }
        });
        rootState.global.company?.countries?.find((country) => {
            if (country.idCountry === user.country_Con) {
                user.country_ConNom = user.country_Con;
                user.country_Con = country.nameCountry;
                country.citys.find((city) => {
                    if (city.idCity === user.city_Con) {
                        user.city_ConNom = user.city_Con;
                        user.city_Con = city.nameCity;
                    }
                });
            }
        });
        return user;
    });
    return newUsers;
};

export const updateGridContacts = (state, getters, rootState) => {
    const newContacts = state.contacts?.map((contact) => {
        rootState.management.companies?.find((company) => {
            if (company.id === contact.associatedCompany) {
                contact.associatedCompany = company.name;
            }
        });
        return contact;
    });
    return newContacts;
};

export const updateWorkGroups = (state) => {
    const newWorkGroups = state.workGroups.map((workgroup) => {
        if (workgroup.members) {
            workgroup.membersObject = workgroup.members;
            workgroup.members = workgroup.members.length;
        }
        return workgroup;
    });
    return newWorkGroups;
};
