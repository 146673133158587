export const logger = {
    log(log, locate) {
      const logs = JSON.parse(localStorage.getItem(`appLogs-${locate}`)) || [];
      const timestamp = new Date();
      logs.push({ log, timestamp });
      localStorage.setItem(`appLogs-${locate}`, JSON.stringify(logs));
    },
    getLogs(locate) {
      return JSON.parse(localStorage.getItem(`appLogs-${locate}`)) || [];
    },
    clearLogs(locate) {
      localStorage.removeItem(`appLogs-${locate}`);
    }
  };