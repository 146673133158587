export default {
    name: '',
    lastName: '',
    email: '',
    clientId: [],
    associatedCompany: '',
    subAreas: [],
    permissions: [],
    category: '00000000-0000-0000-0000-000000000000',
    workingEdition: 0,
    workingDay: 0,
    jobAssignment: {
        workingDays: [],
    },
    roleHierarchy: '',
    isSuperAdmin: false,
    active: true,
    picture: '',
    dailyGoalHoursWorked: 0,
    dailyBillableHoursGoal: 0,
    notificationsParam: [],
    timeOuts: [],
    tenantId: 0,
    isContact: true,
    isPrincipal_Con: false,
    position_Con: '',
    dnI_Con: '',
    country_Con: '',
    city_Con: '',
    phoneNumber_Con: [],
    postalCode_Con: '',
    comments_Con: '',
    type_Con: 0,
    systemUser: false,
};
