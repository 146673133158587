export default {
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ 
    '@/modules/reports/layouts/ReportsLayout.vue'),
    children: [
        {
            path: '',
            name: 'reportsList',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Informes dinámicos', url: 'reports' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "company" */
                    '@/modules/reports/modules/Reports/ReportsListView.vue'
                ),
        },
    ]
}