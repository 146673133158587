export default {
    name: 'production',
    component: () =>
        import(
            /* webpackChunkName: "production" */ '@/modules/production/layouts/ProductionLayout.vue'
        ),
    children: [
        {
            path: 'operative-workflows',
            name: 'operative-workflows',
            component: () =>
                import(
                    /* webpackChunkName: "company" */
                    '@/modules/production/layouts/OperativeWorkflowsLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-operative-workflows',
                    name: 'dash-operative-workflows',
                    redirect: () => {
                        return {
                            name: 'general-production',
                        };
                    },
                },
                {
                    path: 'general-production',
                    name: 'general-production',
                    meta: {
                        breadcrumb: [
                            { name: 'Inicio', url: 'info-cards' },
                            { name: 'Flujos en curso', url: 'grid-workflows' },
                            {
                                name: 'Detalle de flujos',
                                url: 'general-production',
                            },
                        ],
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "company-general" */
                            '@/modules/production/modules/OperativeWorkflows/GeneralView.vue'
                        ),
                    props: (route) => {
                        return {
                            type: route.params.type,
                            id: route.params.id,
                        };
                    },
                },
            ],
        },
        {
            path: 'operative-activities',
            name: 'operative-activities',
            component: () =>
                import(
                    '@/modules/production/layouts/OperativeActivitiesLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-operative-activities',
                    name: 'dash-operative-activities',
                    redirect: () => {
                        return {
                            name: 'general-activities',
                        };
                    },
                },
                {
                    path: 'general-activities',
                    name: 'general-activities',
                    meta: {
                        breadcrumb: [
                            { name: 'Inicio', url: 'info-cards' },
                            { name: 'Actividades', url: 'grid-activities' },
                            {
                                name: 'Detalle de actividades',
                                url: 'general-activities',
                            },
                        ],
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "company-general" */
                            '@/modules/production/modules/OperativeActivities/GeneralView.vue'
                        ),
                    props: (route) => {
                        return {
                            type: route.params.type,
                            id: route.params.id,
                        };
                    },
                },
            ],
        },
        {
            path: 'operative-cases',
            name: 'operative-cases',
            component: () =>
                import(
                    '@/modules/production/layouts/CasesLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-operative-cases',
                    name: 'dash-operative-cases',
                    redirect: () => {
                        return {
                            name: 'general-cases',
                        };
                    },
                },
                {
                    path: 'general-cases',
                    name: 'general-cases',
                    meta: {
                        breadcrumb: [
                            { name: 'Inicio', url: 'info-cards' },
                            { name: 'Casos', url: 'grid-cases' },
                            {
                                name: 'Detalle de casos',
                                url: 'general-cases',
                            },
                        ],
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "company-general" */
                            '@/modules/production/modules/Cases/CaseForm.vue'
                        ),
                    props: (route) => {
                        return {
                            type: route.params.type,
                            id: route.params.id,
                        };
                    },
                },
            ],
        },
    ],
};
