export const updateStateMenu = (state, newState) => {
    state.stateMenu = newState;
};

export const activeMenu = (state, objectMenu) => {
    const [route, active] = objectMenu;
    state.activeMenu[route] = active;
};

export const updateStateLoading = (state, loadingState) => {
    state.loading = loadingState;
};

export const updateCurrentPage = (state, page) => {
    const [currentPage] = page;
    state.currentPage = currentPage;
};

export const getCurrentPageLocalStorage = (state, page) => {
    const [currentPage] = page;
    state.currentPage = currentPage;
};

export const setCompany = (state, companyinfo) => {
    const { data, name } = companyinfo;
    state.organization = {
        ...state.organization,
        id: data.id,
        name,
    };
};

export const changeStep = (state, step) => {
    state.stepForm = step;
};

export const setGeneralCountries = (state, countries) => {
    state.generalCountries = countries;
};

export const setCompanyInfo = (state, info) => {
    state.companyInfo = info;
};

export const setGlobalInfo = (state, info) => {
    state.company = info;
    state.mapcountries = new Map(
        info.countries.map((u) => [u.idCountry, u.citys]),
    );
};

export const setStateShowPopup = (state, showPopup) => {
    state.showPopup1 = false;
    state.showPopup2 = false;
    state.showPopup = showPopup;
};

export const setShowWorks = (state, showPopup) => {
    state.showPopupWorks = showPopup;
};

export const setStateShowPopup1 = (state, showPopup) => {
    state.showPopup = false;
    state.showPopup2 = false;
    state.showPopup1 = showPopup;
};
export const setStateShowPopup2 = (state, showPopup) => {
    state.showPopup1 = false;
    state.showPopup = false;
    state.showPopup2 = showPopup;
};

export const updateChronometer = (state, data) => {
    state.chronometer = data;
};

export const setChronometerState = (state, data) => {
    state.chronometerState = data;
};

export const setChronometerActivity = (state, data) => {
    state.chronometerActivity = data;
};

export const setTenantsByUser = (state, tenantsByUser) => {
    state.tenantsByUser = tenantsByUser;
};

export const setUserProcess = (state, userProcess) => {
    state.userProcess = userProcess;
};

export const setRegisterForm = (state, registerForm) => {
    state.registerForm = registerForm;
};

export const setStateShowSideTab = (state, sideTab) => {
    state.showSideTab = sideTab;
};

export const setDataGridComponentInfo = (state, dataGridComponentInfo) => {
    state.dataGridComponentInfo = dataGridComponentInfo;
};

export const setDataToSideTab = (state, dataToSideTab) => {
    state.dataToSideTab = dataToSideTab;
};

export const setTemplateSideTab = (state, templateSideTab) => {
    state.templateSideTab = templateSideTab;
};

export const setclientData = (state, clientData) => {
    (state.clientData.name = clientData.name),
        (state.clientData.comercialName = clientData.comercialName),
        (state.clientData.nit = clientData.nit),
        (state.clientData.direction = clientData.direction),
        (state.clientData.phone = clientData.phone),
        (state.clientData.contacts = clientData.contacts);
};

export const setProjectData = (state, projectData) => {
    state.projectData.name = projectData.name;
    state.projectData.area = projectData.area;
    state.projectData.subArea = projectData.subArea;
    state.projectData.responsable = projectData.responsable;
    state.projectData.workgroup = projectData.workgroup;
};

export const setCaseData = (state, casetData) => {
    state.casetData = casetData;
};
export const setstatepopup = (state, statepopup) => {
    state.Popup = statepopup;
};

export const stateWizardTitle = (state, stateWizard) => {
    state.wizardTitle = stateWizard;
};

export const setStateNotify = (state, data) => {
    state.stateNotify.push(data);
};

export const setShowProfileCard = (state, statepc) => {
    state.showProfileCard = statepc;
};
export const setUploadIdAWS = (state, UploadIdAWS) => {
    state.UploadIdAWS = UploadIdAWS;
};
export const setPrevETagsAWS = (state, PrevETagsAWS) => {
    state.PrevETagsAWS = PrevETagsAWS;
};

export const setChunksAWS = (state, chunksAWS) => {
    state.chunksAWS = chunksAWS;
};
export const setIdUploadFileS3 = (state, IdUploadFileS3) => {
    state.IdUploadFileS3 = IdUploadFileS3;
};
export const setFromDate = (state, fromDate) => {
    state.fromDate = fromDate;
};

export const setUntilDate = (state, untilDate) => {
    state.untilDate = untilDate;
};

export const setShowWalkthrough = (state, showWalkthrough) => {
    state.showWalkthrough = showWalkthrough;
};

export const setWalkNextTime = (state, walkNextTime) => {
    state.walkNextTime = walkNextTime;
};

export const setWorkActive = (state, workActive) => {
    state.workActive = workActive;
};

export const setShowWalkDashboard = (state, showWalkDashboard) => {
    state.showWalkDashboard = showWalkDashboard;
};

export const setShowWalkDashboardNT = (state, showWalkDashboardNT) => {
    state.showWalkDashboardNT = showWalkDashboardNT;
};

export const setShowWalkFlow = (state, showWalkFlow) => {
    state.showWalkFlow = showWalkFlow;
};
export const setExitForm = (state, exitForm) => {
    state.exitForm = exitForm;
};
export const setShowCustomPopup = (state, showCustomPopup) => {
    state.showCustomPopup = showCustomPopup;
};

export const setShowWalkFlowNT = (state, showWalkFlowNT) => {
    state.showWalkFlowNT = showWalkFlowNT;
};

export const setShowWalkCalendar = (state, showWalkCalendar) => {
    state.showWalkCalendar = showWalkCalendar;
};
export const setShowWalkCalendarNT = (state, showWalkCalendarNT) => {
    state.showWalkCalendarNT = showWalkCalendarNT;
};

export const setShowWalkActivities = (state, showWalkActivities) => {
    state.showWalkActivities = showWalkActivities;
};
export const setShowWalkActivitiesNT = (state, showWalkActivitiesNT) => {
    state.showWalkActivitiesNT = showWalkActivitiesNT;
};

export const setTemplateCustomPopup = (state, template) => {
    state.templateCustomPopup = template;
};
export const setParamsCustomPopup = (state, params) => {
    state.paramsCustomPopup = params;
};
export const setInterval = (state, val) => {
    state.intervalChronometer = val;
};

export const setIdWorkFlowReport = (state, val) => {
    state.idWorkFlowReport = val;
};

export const setShowAssigned = (state, val) => {
    state.showAssigned = val;
};

export const setShowInProcess = (state, val) => {
    state.showInProcess = val;
};

export const setShowFinished = (state, val) => {
    state.showFinished = val;
};

export const setIsExpandView = (state, val) => {
    state.isExpandView = val;
};

export const setTypeCalendarActivities = (state, val) => {
    state.typeCalendarActivities = val;
};

export const setShowWalkActivitiesCreate = (
    state,
    showWalkActivitiesCreate,
) => {
    state.showWalkActivitiesCreate = showWalkActivitiesCreate;
};
export const setShowWalkActivitiesCreateNT = (
    state,
    showWalkActivitiesCreateNT,
) => {
    state.showWalkActivitiesCreateNT = showWalkActivitiesCreateNT;
};

export const setShowWalkDashFlows = (state, showWalkDashFlows) => {
    state.showWalkDashFlows = showWalkDashFlows;
};
export const setShowWalkDashFlowsNT = (state, showWalkDashFlowsNT) => {
    state.showWalkDashFlowsNT = showWalkDashFlowsNT;
};
export const localPermissions = (state, localPermissions) => {
    state.localPermissions = localPermissions;
};
export const setSeletedTeam = (state, seletedTeam) => {
    state.seletedTeam = seletedTeam;
};
export const setSeletedUser = (state, seletedUser) => {
    state.seletedUser = seletedUser;
};
export const setSeletedAreas = (state, seletedAreas) => {
    state.seletedAreas = seletedAreas;
};
export const setSeletedSubareas = (state, seletedSubareas) => {
    state.seletedSubareas = seletedSubareas;
};
export const setSeletedClients = (state, seletedClients) => {
    state.seletedClients = seletedClients;
};
export const setDeleteFilter = (state, deleteFilter) => {
    state.deleteFilter = deleteFilter;
};
export const setApplyFilter = (state, applyFilter) => {
    state.applyFilter = applyFilter;
};
export const setUserCompanies = (state, userCompanies) => {
    state.userCompanies = userCompanies;
};
export const setChangeCompany = (state, changeCompany) => {
    state.changeCompany = changeCompany;
};
export const setCompanyImage = (state, CompanyImage) => {
    state.companyImage = CompanyImage;
};
export const setS3UrlDocument = (state, S3UrlDocument) => {
    state.S3UrlDocument = S3UrlDocument;
};
export const setNameS3UrlDocument = (state, nameS3UrlDocument) => {
    state.nameS3UrlDocument = nameS3UrlDocument;
};
export const setShowPopupSessionUser = (state, showPopupSessionUser) => {
    state.showPopupSessionUser = showPopupSessionUser;
};
export const setGeneralStateNotify = (state, data) => {
    state.stateNotify = data;
};
export const setStateShowSideTabCitation = (state, sideTabCitation) => {
    state.showSideTabCitation = sideTabCitation;
};

export const setHabilitySesionPopup = (state, habilitySesionPopup) => {
    state.habilitySesionPopup = habilitySesionPopup;
};
/**
 * Se setea el titulo del modulo
 * @param {Object} state - Vuex state
 * @param {String} moduleTitle - El titulo del modulo
 */
export const setModuleTitle = (state, moduleTitle) => {
    state.moduleTitle = moduleTitle;
};
