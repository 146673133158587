import managementApi from "@/api/managementApi";

export const getDocumentsByFile = async ({ commit },objfile) => {
    const { data } = await managementApi.post(`FilesS3/GetfilterforFiles`,objfile);
    commit('GetDocuments', data.data);
    return data.data
};
export const getSiacClients = async ({ commit },objfile) => {
    const { data } = await managementApi.get(`InfoFilesMigrated/GetClientAll`);
    commit('setSiacClients', data.data);
    return data.data
};
export const getSiacUsers = async ({ commit },objfile) => {
    const { data } = await managementApi.get(`InfoFilesMigrated/GetUsersAll`);
    commit('setSiacUsers', data.data);
    return data.data
};
export const getDocumentSiac = async ({ commit },objfile) => {
    const { data } = await managementApi.post(`FilesS3/GetfilterforFilesMigrate`, objfile);
    commit('GetDocuments', data.data);
    return data.data
};