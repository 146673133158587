export default {
    general: {
        welcome: '',
        errorTenant: '',
        placeholderTenant: '',
        labelTenant: '',
        errorNoData: '',
        requireSearchData: '',
        addFields: '',
        viewDetail: '',
        gridEmptyMessage: '',
        daysOfWeek: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
        },
        operativeActivityState: {
            Assigned: '',
            InProcess: '',
            RequestForClarification: '',
            AwaitingRevision: '',
            AuditedByLeader: '',
            AwaitingPreInvoice: '',
            PreInvoiceInRevision: '',
            Billed: '',
        },
        hours: '',
        tradeAgreementType: {
            FeePerHour: '',
            StaticFee: '',
            Retainer: '',
            LandmarkByTypeActivity: '',
            LandmarkByEvent: '',
            LimitFee: '',
            ByService: '',
            NoBillable: '',
            default: '',
            FeePerHourInterval: '',
        },
        contactType: {
            billing: '',
            production: '',
            comercial: '',
            notContact: '',
        },
        save: '',
        cancel: '',
        noApply: '',
        edit: '',
        cancelEdit: '',
        actions: '',
    },
    'need-help': {
        title: '',
        subtitle: '',
        placeholderEmail: '',
        labelEmail: '',
        errorEmail: '',
        errorTypeEmail: '',
        labelConcern: '',
        placeholderConcern: '',
        errorConcern: '',
        sendButton: '',
    },
    management: {
        title: '',
        subtitle: '',
        company: {
            'dash-general': '',
            'title-button': '',
            'title-general-form': '',
            'title-update-general-form': '',
            'form-type-company': {
                'label-logo': '',
                'replace-copy-image-first': '',
                'copy-image-second': '',
                formats: '',
                placeholderName: '',
                labelName: '',
                errorName: '',
                placeholderUniqueIdentificationNumber: '',
                labelUniqueIdentificationNumber: '',
                errorUniqueIdentificationNumber: '',
                placeholderPhone: '',
                labelPhone: '',
                errorPhone: '',
                errorPhoneNumeric: '',
                placeholderAddress: '',
                labelAddress: '',
                errorAddress: '',
                placeholderCountry: '',
                labelCountry: '',
                errorCountry: '',
                placeholderCity: '',
                labelCity: '',
                errorCity: '',
                placeholderEmail: '',
                labelEmail: '',
                errorEmail: '',
                labelMain: '',
                cancelButton: '',
                saveButton: '',
                updateButton: '',
                messageCreate: '',
                messageUpdate: '',
            },
            notifications: {
                messageSaveSuccess: '',
                notificationsUsersTitle: '',
                notificationsUsersParagraph: '',
                notificationsProyectTitle: '',
                notificationsProyectParagraph: '',
                notificationsFlowTitle: '',
                notificationsFlowParagraph: '',
            },
        },
        system: {
            labelTimeZone: 'Zona horaria',
            placeholderTimeZone: 'Selecciona tu zona horaria',
            errorTimeZone: 'La zona horaria es requerida',
            labelLanguage: 'Idioma',
            placeholderLanguage: 'Selecciona tu idioma',
            errorLanguage: 'El idioma es requerido',
            placeholderDailyWorkedHours:
                'Escribe la meta diaria de horas trabajadas',
            labelDailyWorkedHours: 'Meta diaria de horas trabajadas',
            errorDailyWorkedHours:
                'La meta diaria de horas trabajadas es requerida',
            errorDailyWorkedHoursNumber:
                'La meta diaria de horas trabajadas solo permite números',
            placeholderDailyBilledHours:
                'Escribe la meta día de horas facturadas',
            labelDailyBilledHours: 'Meta diaria de horas facturadas',
            errorDailyBilledHours:
                'La meta diaria de horas facturadas es requerida',
            errorDailyBilledHoursNumber:
                'La meta diaria de horas facturadas solo permite números',
            placeholdertypeRestrictionActivityTime: 'Selecciona la restricción',
            labeltypeRestrictionActivityTime:
                'Restricción de ingreso y edición de trabajo',
            errortypeRestrictionActivityTime:
                'La restricción de ingreso y edición de trabajo es requerida',
            all: 'No tiene restricción',
            day: 'Día',
            weekly: 'Semanal',
            monthly: 'Mensual',
            titleDaysForm: 'Días laborales',
            dayOne: 'Lun',
            dayTwo: 'Mar',
            dayThree: 'Mie',
            dayFour: 'Jue',
            dayFive: 'Vie',
            daySix: 'Sab',
            daySeven: 'Dom',
            placeholderdeliveryTime: 'Selecciona el tipo de fecha de entrega',
            labeldeliveryTime: 'Entrega fin del día',
            labeldeliveryTimeOptionTrue: 'Fecha de entrega al final del día',
            labeldeliveryTimeOptionFalse: 'Fecha de entrega a la hora exacta',
            errordeliveryTime: 'La entrega fin del día es requerida',
            endDeliveryActivity: 'Fecha de entrega a la hora exacta',
            endDeliveryActivityDay: 'Fecha de entrega al final del día',
            placeholdertypeBillableTime:
                'Selecciona el tipo de tiempo a facturar',
            labeltypeBillableTime: 'Tiempo facturable',
            errortypeBillableTime: 'El tipo de tiempo facturable es requerido',
            estimatedTime: 'Tiempo estimado',
            usedTime: 'Tiempo trabajado',
            placeholderDailyHourRegistrationTimeLimit:
                'Ingresa el límite de tiempo de registro',
            labelDailyHourRegistrationTimeLimit: 'Límite de tiempo de registro',
            dailyHourRegistrationTimeLimit:
                'El tiempo límite de registro es requerido',
            placeholderinitialWorkHour: 'Ingresa la hora de inicio laboral',
            labelinitialWorkHour: 'Hora de inicio laboral',
            placeholderfirstEndHour: 'Proxima a vencerse',
            labelfirstEndHour: 'Rango de oportunidad próxima a vencerse',
            errorfirstEndHour:
                'el rango de oportunidad próxima a vencerse es requerido',
            placeholdersecondEndHour: 'Vencida',
            labelsecondEndHour: 'Rango de oportunidad vencida',
            placeholderProcessFunction: 'Casos',
            labelProcessFunction: 'Inclusión de casos',
            optionProcessOne: 'Si',
            optionProcessTwo: 'No',
            errorsecondEndHour: 'el rango de oportunidad vencida es requerido',
            placeholderHoursTimeAlertActivitiesRevision:
                'El Tiempo alerta revisión de actividades es requerido',
            labelHoursTimeAlertActivitiesRevision:
                'Tiempo alerta revisión de actividades (Horas)',
            errorHoursTimeAlertActivitiesRevision:
                'El Tiempo alerta revisión de actividades es requerido',
            errorinitialWorkHour: 'La hora de inicio laboral es requerida',
            deliveryTime: 'Eltiempo de entrega fin es requerido',
        },
        'functional-structure': {
            'dash-practice': '',
            'dash-service-units': '',
            'dash-activities': '',
            placeholderSearch: '',
            placeholderBilling: '',
            'general-title-type-activity': '',
            'title-type-activity': '',
            'subtitle-type-activity': '',
            'form-type-activity': {
                labelNameArea: '',
                placeholderNameArea: '',
                errorNameArea: '',
                labelNameSubArea: '',
                placeholderNameSubArea: '',
                errorNameSubArea: '',
                labelNameActivity: '',
                placeholderNameActivity: '',
                errorNameActivity: '',
                labelCode: '',
                placeholderCode: '',
                labelEstimatedTime: '',
                placeholderEstimatedTime: '',
                errorEstimatedTime: '',
                labelAveragingTimeUsed: '',
                placeholderAveragingTimeUsed: '',
                labelGroupCaption: '',
                labelFirstEndHour: '',
                placeholderFirstEndHour: '',
                errorFirstEndHour: '',
                labelLastEndHour: '',
                placeholderLastEndHour: '',
                errorLastEndHour: '',
                labelAppointmentSchedule: '',
                labelRequiredDocumments: '',
                labelRequiredRevision: '',
                labelExclusiveFlows: '',
                labelDescriptionActivity: '',
                placeholderDescriptionActivity: '',
                cancelButton: '',
                saveButton: '',
                updateButton: '',
                messageCreate: '',
                messageUpdate: '',
                labelIsBillable: '',
                labelButtonSubtheme: '',
                labelNewSubtheme: '',
            },
            'form-activity': {
                placeholderNameProject: '',
                labelNameProject: '',
                errorNameProject: '',
                placeholderNameArea: '',
                labelNameArea: '',
                errorNameArea: '',
                placeholderNameSubArea: '',
                labelNameSubArea: '',
                errorNameSubArea: '',
                placeholderNameTypeActivity: '',
                labelNameTypeActivity: '',
                errorNameTypeActivity: '',
                placeholderDescriptionActivity: '',
                labelDescriptionActivity: '',
                errorDescriptionActivity: '',
                dividerText: '',
                timeWorked: '',
                minutes: '',
                hours: '',
                personCharge: '',
                placeholderPersonCharge: '',
                startDate: '',
                placeholderStartDate: '',
                startDelivery: '',
                placeholderStartDelivery: '',
                estimatedTime: '',
                estimatedTimeMinutes: '',
                estimatedTimeHours: '',
            },
            'dash-flows': '',
            'title-button-add-flows': '',
            'title-button-add-more-fields': '',
            'form-flows': {
                titleFlow: '',
                titleFlowUpdate: '',
                subtitleFlow: '',
                submoduleTitle: '',
                placeholderNameArea: '',
                labelNameArea: '',
                errorNameArea: '',
                placeholderNameSubArea: '',
                labelNameSubArea: '',
                errorNameSubArea: '',
                placeholderNameFlow: '',
                labelNameFlow: '',
                errorNameFlow: '',
                placeholderCode: '',
                labelCode: '',
                placeholderTypeForm: '',
                labelTypeForm: '',
                errorTypeForm: '',
                placeholderDescriptionActivity: '',
                labelDescriptionActivity: '',
                labeltitleNewFlow: '',
                cancelButton: '',
                saveButton: '',
                updateButton: '',
                messageCreate: '',
                messageUpdate: '',
                messageError: '',
            },
            'dash-alerts': {
                emptyState: '',
                addButton: '',
            },
            'general-alerts': {
                name: '',
                methodSend: '',
                placeholderMethodSend: '',
                placeholderName: '',
                typeNotification: '',
                placeholderType: '',
                placeholderDescription: '',
                description: '',
                errorName: '',
                errorTypeSend: '',
                errorTypeNotification: '',
                messageSaveUpdateSuccess: '',
                messageSaveCreateSuccess: '',
                subtitleAlert: '',
                submoduleTitle: '',
                placeholderDays: '',
                requiredDays: '',
                placeholderActivity: '',
                requiredActivity: '',
                placeholderDate: '',
                requiredDate: '',
                conditionLabel: '',
                typeOne: {
                    one: '',
                    two: '',
                    three: '',
                },
                typeTwo: {
                    one: '',
                    two: '',
                    three: '',
                    four: '',
                },
                typeThree: {
                    one: '',
                    two: '',
                },
                initialDate: '',
                deliveryDate: '',
                downloadDate: '',
                typeOneNotification: '',
                typeTwoNotification: '',
                typeThreeNotification: '',
                email: '',
                push: '',
            },
            'dash-dynamic-forms': '',
            'title-button-dynamic-forms': '',
            'dynamic-form': {
                titleDynamicForm: '',
                titleDynamicFormStepTwo: '',
                subtitleDynamicForm: '',
                submoduleTitle: '',
                titleCardDynamicForm: '',
                placeholderName: '',
                labelName: '',
                errorName: '',
                placeholderCodigo: '',
                labelCodigo: '',
                placeholderDescripcion: '',
                labelDescripcion: '',
                titleColumnOne: '',
                titleForm: ':',
                titleColumnTwo: '',
                dxCheckBox: '',
                dxDateBox: '',
                dxNumberBox: '',
                dxRadioGroup: '',
                dxSelectBox: '',
                dxSwitch: '',
                dxTextArea: '',
                dxTextBox: '',
                backButton: '',
                nextButton: '',
                cancelButton: '',
                saveButton: '',
                updateButton: '',
                deleteButton: '',
                placeholderTitle: '',
                labelTitle: '',
                errorTitle: '',
                placeholderTexto: '',
                labelTexto: '',
                labelCheckRequired: '',
                placeholderMessaggeRequired: '',
                labelMessageRequired: '',
                labelCheckLength: '',
                labelCheckMultiple: '',
                placeholderMessaggeLength: '',
                placeholderLimitMultipleSelection: '',
                labelMessageLength: '',
                labelLimitMultipleSelection: '',
                placeholderMinLength: '',
                labelMinLength: '',
                placeholderMaxLenght: '',
                labelMaxLenght: '',
                errorMinLength: '',
                errorMin: '',
                errorMax: '',
                errorMaxLenght: '',
                errorMessageLength: '',
                labelColumns: '',
                labelDate: '',
                placeholderHeight: '',
                labelHeight: '',
                onlyNumbers: '',
                errorDynamicForm: '',
            },
            'dash-users': '',
            'title-button-users': '',
            'users-forms': {
                titleUsersForm: '',
                subtitleUsersForm: '',
                submoduleTitle: '',
                placeholderNameUsers: '',
                labelNameUsers: '',
                errorNameUsers: '',
                errorPatternName: '',
                labelLastName: '',
                placeholderLastName: '',
                errorLastName: '',
                errorPatternLastName: '',
                placeholderDni: '',
                labelDni: '',
                errorDni: '',
                placeholderCategory: '',
                labelCategory: '',
                errorCategory: '',
                placeholderCode: '',
                labelCode: '',
                placeholderAssociatedCompany: '',
                labelAssociatedCompany: '',
                errorAssociatedCompany: '',
                placeholderCountry: '',
                labelCountry: '',
                errorCountry: '',
                placeholderCity: '',
                labelCity: '',
                errorCity: '',
                placeholderWorkday: '',
                labelWorkday: '',
                errorWorkday: '',
                placeholderDailyGoalHoursWorked: '',
                labelDailyGoalHoursWorked: '',
                errorDailyGoalHoursWorked: '',
                errorDailyGoalHoursWorkedNumber: '',
                placeholderDailyBillableHoursGoal: '',
                labelDailyBillableHoursGoal: '',
                errorDailyBillableHoursGoal: '',
                errorDailyBillableHoursGoalNumber: '',
                fullTime: '',
                halfTime: '',
                placeholderEmail: '',
                labelEmail: '',
                errorEmail: '',
                placeholderRestriction: '',
                labelRestriction: '',
                errorRestriction: '',
                all: '',
                day: '',
                weekly: '',
                monthly: '',
                titleDaysForm: '',
                dayOne: '',
                dayTwo: '',
                dayThree: '',
                dayFour: '',
                dayFive: '',
                daySix: '',
                daySeven: '',
                titleCardWorkGroup: '',
                titleCardPractice: '',
                titleCardUsers: '',
                titleCardRoles: '',
                backButton: '',
                nextButton: '',
                cancelButton: '',
                viewButton: '',
                saveButton: '',
                updateButton: '',
                placeholderDateOfAdmission: '',
                labelDateOfAdmission: '',
                errorDateOfAdmission: '',
            },
            'dash-work-groups': '',
            'title-button-work-groups': '',
            'work-groups-forms': {
                titleWorkGroupsForm: '',
                subtitleWorkGroupsForm: '',
                submoduleTitle: '',
                placeholderNameWorkGroups: '',
                labelNameWorkGroups: '',
                errorNameWorkGroups: '',
                placeholderCode: '',
                labelCode: '',
                placeholderDescriptionWorkGroup: '',
                labelDescriptionWorkGroup: '',
                errorDescriptionWorkGroup: '',
                stepTwoSubmoduleTitle: '',
            },
            'dash-roles': '',
            'title-button-roles': '',
            'roles-forms': {
                titleRolesForm: '',
                subtitleRolesForm: '',
                submoduleTitle: '',
                placeholderNameRoles: '',
                labelNameRoles: '',
                errorNameRoles: '',
                placeholderCode: '',
                labelCode: '',
                placeholderDescriptionWorkGroup: '',
                labelDescriptionWorkGroup: '',
                errorDescriptionRol: '',
                titleCardPermissions: '',
            },
            'workflows-diagrama-component': {
                label: '',
                link: {
                    tooltip: '',
                    text: '',
                },
                rectangle: {
                    isInLoop: '',
                    titleSection: '',
                    isAutoAssignable: '',
                    stage: '',
                    time1: '',
                    time2: '',
                },
                polygon: {
                    titleSection: '',
                    desitionText: '',
                },
            },
            'indexing-forms': {
                titlebutton: '',
                titleindexingForm: '',
                subtitleIndexingForm: '',
                submoduleTitle: '',
                placeholderName: '',
                labelName: '',
                placeholderStandar: '',
                placeholderEquivalencia: '',
                labelEquivalencia: '',
                labelStandar: '',
                placeholderAbreviatura: '',
                labelAbreviatura: '',
                errorName: '',
                errorPattern: '',
            },
        },
        project: {
            'dash-projects': '',
            'title-button-projects': '',
            'title-button-add-projects': '',
            'projects-forms': {
                titleProjectsForm: '',
                subtitleProjectsForm: '',
                submoduleTitle: '',
                submoduleTitleStepTwo: '',
                submoduleTitleStepThree: '',
                placeholderNameProject: '',
                labelNameProject: '',
                errorNameProject: '',
                placeholderCompany: '',
                labelCompany: '',
                errorCompany: '',
                placeholderDescriptionProject: '',
                labelDescriptionProject: '',
                errorDescriptionProject: '',
                placeholderRequestedBy: '',
                labelRequestedBy: '',
                errorRequestedBy: '',
                titleCardArea: '',
                titleCardSubAreas: '',
                copyImageFirst: '',
                copyImageSecond: '',
                formats: '',
                labelCardsMenbers: '',
                titleCardCommercialManager: '',
                titleCardLoyaltyGroup: '',
                labelCardsPrivacy: '',
                titleCardWorkGroups: '',
                titleCardUsers: '',
                errorCommercialManager: 'L',
                errorLoyaltyGroup: '',
                backButton: '',
                nextButton: '',
                cancelButton: '',
                saveButton: '',
                updateButton: '',
                emptyPractice: '',
            },
            'trade-agreement': {
                dash: '',
                buttonAdd: '',
                'trade-agreement-form': {
                    titleFirstForm: '',
                    titleSecondForm: '',
                    titleThirdForm: '',
                    placeholderStartDate: '',
                    labelStartDate: '',
                    errorStartDate: '',
                    placeholderFinalDate: '',
                    labelFinalDate: '',
                    errorFinalDate: '',
                    placeholderIndexingUnit: '',
                    labelIndexingUnit: '',
                    errorIndexingUnit: '',
                    placeholderDescriptionProject: '',
                    labelDescriptionProject: '',
                    errorDescription: '',
                    rateTypeOne: '',
                    rateTypeTwo: '',
                    includeTopHoursLabel: '',
                    includeAccumulativeLabel: '',
                    includeAccumulativePlaceholder: '',
                    topHoursLabel: '',
                    rateTypeThree: '',
                    labelIntervaleOne: '',
                    labelIntervaleTwo: '',
                    labelPeriodTime: '',
                    placeHolderPeriodTime: '',
                    typeOnePeriod: '',
                    typeTwoPeriod: '',
                    typeThreePeriod: '',
                    typeFourPeriod: '',
                    typeFivePeriod: '',
                    intervalsDefLabel: '',
                    valueHours: '',
                    defaultValueLabel: '',
                    includeTopHoursPlaceholder: '',
                    includeOptionOne: '',
                    includeOptionTwo: '',
                    TopValueHoursLabel: '',
                    placeholderTopHourValue: '',
                    labelRateType: '',
                    placeholderCurrency: '',
                    labelCurrency: '',
                    placeholderRate: '',
                    labelRate: '',
                    placeholderHourValue: '',
                    labelHourValue: '',
                    placeholderInvoicedIn: '',
                    labelInvoicedIn: '',
                    labelLedes: '',
                    placeholderObservationsProject: '',
                    labelObservationsProject: '',
                    placeholderMonthAmount: '',
                    labelMonthAmount: '',
                    placeholderHourAmountAverage: '',
                    labelHourAmountAverage: '',
                    labelFixedAmount: '',
                    labelRetainerOne: '',
                    labelDistribution: '',
                    labelRetainerTwo: '',
                    labelHoursAvailableOne: '',
                    labelHoursAvailableTwo: '',
                    year: '',
                    month: '',
                    placeholderValidity: '',
                    labelState: '',
                    labelAdditionalHours: '',
                    labelAccumulation: '',
                    noAccumulation: '',
                    distributionMonth: '',
                    distributionTotal: '',
                    accumulation: '',
                    labelMilestones: '',
                    milestonesTypeOne: '',
                    milestonesTypeTwo: '',
                    errorEmptyGrid: '',
                    renewalContractLabel: 'Renovación automática',
                    takeLastReferenceDateLabel: 'Incremento inicio de año',
                },
            },
            activities: {
                dash: '',
                buttonAdd: 'Agregar Flujo',
            },
            bills: {
                dash: '',
                buttonAdd: '',
                'bills-form': {
                    titleBillsForm: '',
                    placeholderBills: '',
                    labelBills: '',
                    errorBills: '',
                    placeholderCurrency: '',
                    labelCurrency: '',
                    errorCurrency: '',
                    placeholderBillValue: '',
                    labelBillValue: '',
                    errorBillValue: '',
                    placeholderDescriptionBill: '',
                    labelDescriptionBill: '',
                    placeholderDateBill: '',
                    labelDateBill: '',
                    errorDateBill: '',
                    expenseSupport: '',
                    copyImageFirst: '',
                },
            },
            'shotdown-project': {
                label: 'El proyecto ha sido desactivado correctamente',
                labelError: 'No se ha podido desactivar el proyecto',
                msgDeactivateError:
                    'No es posible desactivar el rol, está asignado a uno o mas usuarios.',
                msgDeactivateCurrency:
                    'Debes asignar otra moneda como principal, para poder desactivar esta moneda',
                msgEditTradeAgreement:
                    ' Para editar el acuerdo comercial; debes activar el proyecto asociado',
                msgContactUpdated: 'Contacto actualizado exitosamente',
                msgContactsWarning:
                    'Este contacto puede estar enlazado a uno o más clientes. Si se desactiva se podrían perder los enlaces y los respectivos permisos. Continuar?',
            },
        },
        client: {
            dash: '',
            create: {
                title: '',
                titleEdit: '',
                newClient: '',
                labelNaturalPersonType: '',
                labelJuridicPersonType: '',
                errorPersonType: '',
                placeholderNameClient: '',
                labelNameClient: '',
                errorNameClient: '',
                placeholderCode: '',
                labelCode: '',
                placeholderComercialName: '',
                labelComercialName: '',
                errorComercialName: '',
                placeholderDocumentType: '',
                labelDocumentType: '',
                errorDocumentType: '',
                placeholderDocumentNumber: '',
                labelDocumentNumber: '',
                errorDocumentNumber: '',
                placeholderEmpresarialGroup: '',
                labelEmpresarialGroup: '',
                errorEmpresarialGroup: '',
                placeholderDateVinculation: '',
                labelDateVinculation: '',
                errorDateVinculation: '',
                placeholderComercialResponsable: '',
                labelComercialResponsable: '',
                errorComercialResponsable: '',
                placeholderState: '',
                labelState: '',
                placeholderCountry: '',
                labelCountry: '',
                errorCountry: '',
                placeholderCity: '',
                labelCity: '',
                errorCity: '',
                placeholderPostalCode: '',
                labelPostalCode: '',
                errorPostalCode: '',
                placeholderDirection: '',
                labelDirection: '',
                errorDirection: '',
                placeholderPhoneNumber: '',
                labelPhoneNumber: '',
                errorPhoneNumber: '',
                placeholderEmail: '',
                labelEmail: '',
                errorEmail: '',
                errorNumberPermit: '',
                errorEmailPermit: '',
            },
            contact: {
                title: '',
                titleEdit: '',
                newContact: '',
                placeholderNameClient: '',
                labelNameClient: '',
                errorNameClient: '',
                placeholderLastName: '',
                labelLastName: '',
                errorLastName: '',
                placeholderPosition: '',
                labelPosition: '',
                errorPosition: '',
                placeholderDocumentNumber: '',
                labelDocumentNumber: '',
                errorDocumentNumber: '',
                placeholderBussinessName: '',
                labelBussinessName: '',
                placeholderCode: '',
                labelCode: '',
                placeholderComercialName: '',
                labelComercialName: '',
                errorComercialName: '',
                placeholderDocumentType: '',
                labelDocumentType: '',
                errorDocumentType: '',
                placeholderEmpresarialGroup: '',
                labelEmpresarialGroup: '',
                errorEmpresarialGroup: '',
                placeholderDateVinculation: '',
                labelDateVinculation: '',
                errorDateVinculation: '',
                placeholderComercialResponsable: '',
                labelComercialResponsable: '',
                errorComercialResponsable: '',
                placeholderState: '',
                labelState: '',
                placeholderCountry: '',
                labelCountry: '',
                errorCountry: '',
                placeholderCity: '',
                labelCity: '',
                errorCity: '',
                placeholderPostalCode: '',
                labelPostalCode: '',
                errorPostalCode: '',
                placeholderDirection: '',
                labelDirection: '',
                errorDirection: '',
                placeholderPhoneNumber: '',
                labelPhoneNumber: '',
                errorPhoneNumber: '',
                placeholderEmail: '',
                labelEmail: '',
                errorEmail: '',
                errorNumberPermit: '',
                errorEmailPermit: '',
                labelType: '',
                placeholderType: '',
                labelMain: '',
            },
        },
        menu: {
            stepOne: {
                name: '',
                sideMenuByStep: {
                    subStepOne: '',
                    subStepTwo: '',
                    subStepThree: '',
                    subStepFour: '',
                    subStepFive: '',
                    subStepSix: '',
                    subStepSeven: '',
                },
            },
            stepTwo: {
                name: '',
                sideMenuByStep: {
                    subStepOne: '',
                    subStepTwo: '',
                    subStepThree: '',
                    subStepFour: {
                        name: '',
                        subMenus: {
                            subMenuOne: '',
                            subMenuTwo: '',
                            subMenuThree: '',
                        },
                    },
                    subStepFive: {
                        name: '',
                        subMenus: {
                            subMenuOne: '',
                            subMenuTwo: '',
                        },
                    },
                },
            },
            stepThree: {
                name: '',
                sideMenuByStep: {
                    subStepOne: {
                        name: '',
                        subMenus: {
                            subMenuOne: '',
                            subMenuTwo: '',
                        },
                    },
                    subStepTwo: {
                        name: '',
                        subMenus: {
                            subMenuOne: '',
                            subMenuTwo: '',
                        },
                    },
                    subStepThree: {
                        name: '',
                    },
                },
            },
            stepFour: {
                name: '',
                sideMenuByStep: {
                    subStepone: '',
                    subStepTwo: '',
                },
            },
            stepFive: {
                name: '',
                sideMenuByStep: {
                    subStepOne: '',
                    subStepTwo: '',
                    subStepThree: '',
                    subStepFour: '',
                    subStepFive: '',
                },
            },
            stepSix: {
                name: '',
                sideMenuByStep: {},
            },
        },
    },
    register: {
        paragraph: '',
        paragraphTwo: '',
        itemOne: '',
        itemTwo: '',
        itemThree: '',
        itemFour: '',
        itemFive: '',
        titleForm: '',
        userTitle: '',
        labelName: '',
        placeholderName: '',
        errorName: '',
        errorPatternName: '',
        labelLastName: '',
        placeholderLastName: '',
        errorLastName: '',
        errorPatternLastName: '',
        labelEmail: '',
        placeholderEmail: '',
        companyTitle: '',
        labelNameCompany: '',
        placeholderNameCompany: '',
        errorNameCompany: '',
        labelCountry: '',
        placeholderCountry: '',
        errorCountry: '',
        labelCity: '',
        placeholderCity: '',
        errorCity: '',
        labelAddress: '',
        placeholderAddress: '',
        errorAddress: '',
        labelPhone: '',
        placeholderPhone: '',
        errorPhone: '',
        errorNumericPhone: '',
        labelTimeZone: '',
        placeholderTimeZone: '',
        errorTimeZone: '',
        labelLanguage: '',
        placeholderLanguage: '',
        errorLanguage: '',
        titleUploadImage: '',
        subTitleUploadImage: '',
        copyImageFirst: '',
        copyImageSecond: '',
        formats: '',
        backButton: '',
        nextButton: '',
        skipButton: '',
        errorUploadFile: '',
        errorImageWeight: '',
    },
    production: {
        title: '',
        subtitle: '',
        historyTitle: 'Bitácora',
        dashboard: {
            'activities-summary': '',
            'dash-activities-ops': '',
            'dash-activities-ops-complete': '',
            'dash-workflows-open': '',
            'dash-workflows-close': '',
            titleHeader: '',
            // allactivities:'',
            allactivities: '',
            card1: '',
            card2: '',
            card3: '',
            card4: '',
            activitiescoplete: '',
            'dxScheduler-switcherWeek': '',
            'dxScheduler-switcherMonth': '',
            open: '',
            close: '',
            filterTitle: '',
        },
        cases: {
            buttonAdd: '',
        },
        'cases-details': {
            CaseTitleplaceholder: '',
            CaseTitlelabel: '',
            CaseTitleError: '',
            CaseDescriptionplaceholder: '',
            CaseDescriptionlabel: '',
            tabPanel: {
                activities: { title: '' },
                workflow: { title: '' },
            },
            defendants: {
                buttonAdd: '',
            },
            plaintiffs: {
                buttonAdd: '',
            },
        },
        'activities-details': {
            title: '',
            subTitle: '',
            tabPanel: {
                general: {
                    TitleActivityForm: '',
                    title: '',
                    ActivityTitlelabel: '',
                    ActivityTitleplaceholder: '',
                    ActivityTitleError: '',
                    Projectlabel: '',
                    ProjectPlaceHolder: '',
                    ProjectError: '',
                    APSUlabel: '',
                    APSUplaceholder: '',
                    APSUerror: '',
                    Subtopicerror: '',
                    Hourserror: '',
                    Minuteserror: '',
                    Descriptionerror: '',
                    Caselabel: '',
                    Caseplaceholder: '',
                    Caseerror: '',
                    Subthemelabel: '',
                    Subthemeplaceholder: '',
                    Subthemeerror: '',
                    Descriptionlabel: '',
                    Descriptionplaceholder: '',
                    DescriptionError: '',
                    Workedtimelabel: '',
                    WithoutTitle: '',
                    HoursPlaceHolder: '',
                    MinutesPlaceHolder: '',
                    Workedtimeplaceholder: '',
                    Workedtimeerror: '',
                    Billabletimelabel: '',
                    Billabletimeplaceholder: '',
                    Billabletimeerror: '',
                    Expectedtimelabel: '',
                    Expectedtimeplaceholder: '',
                    Expectedtimeerror: '',
                    Facturablelabel: '',
                    Activityresponsablelabel: '',
                    Activityresponsableplaceholder: '',
                    ActivityResponsableError: '',
                    Startdatelabel: '',
                    Startdateplaceholder: '',
                    Startdateerror: '',
                    Deliverdatelabel: '',
                    Deliverdateplaceholder: '',
                    Deliverdateerror: '',
                    Downloaddatelabel: '',
                    Downloaddateplaceholder: '',
                    Downloaddateerror: '',
                    Fileslabel: '',
                    Fileserror: '',
                    messageCreate: '',
                    messageUpdate: '',
                    messageDownload: '',
                    messageShare: '',
                    workLabelCheck: '',
                    typeMessageWork: '',
                    typeMessageWorkPlaceHolder: '',
                    messageErrorShare: '',
                    messageIncompleteTask: '',
                    messageRequireFiles: '',
                },
                expenses: {
                    title: '',
                    placeholderExpense: '',
                    labelExpense: '',
                    errorExpense: '',
                    placeholderCurrency: '',
                    labelCurrency: '',
                    errorCurrency: '',
                    placeholderValue: '',
                    labelValue: '',
                    errorValue: '',
                    placeholderDescription: '',
                    labelDescription: '',
                    errorDescription: '',
                    Fileslabel: '',
                    Datelabel: '',
                    Dateplaceholder: '',
                    Dateerror: '',
                    messageCreate: '',
                    messageUpdate: '',
                    messageError: '',
                },
                share: {
                    title: '',
                    Profesionallabel: '',
                    Profesionalplaceholder: '',
                    Profesionalerror: '',
                },
            },
        },
        'workflows-details': {
            title: '',
            subTitle: '',
            tabPanel: {
                general: {
                    title: '',
                    labelName: '',
                    placeholderName: '',
                    errorName: '',
                    labelArea: '',
                    placeholderArea: '',
                    errorArea: '',
                    labelSubArea: '',
                    placeholderSubArea: '',
                    errorSubArea: '',
                    labelProcess: '',
                    placeholderProcess: '',
                    labelWorkflowType: '',
                    placeholderWorkflowType: '',
                    errorWorkflowType: '',
                    labelWorkgroup: '',
                    placeholderWorkgroup: '',
                    errorWorkgroup: '',
                    labelResponsable: '',
                    placeholderResponsable: '',
                    errorResponsable: '',
                    labelTitle: '',
                    placeholderTitle: '',
                    errorTitle: '',
                    labelDescription: '',
                    placeholderDescription: '',
                    errorDescription: '',
                    projectWithoutDescription: 'Proyecto sin descripción',
                    tooltipProjectTitle: 'Descripción:',
                },
                workflowType: {
                    title: '',
                },
                tradeAgreement: {
                    title: '',
                    labelSelectTradeAgreement: '',
                    noDescription: 'Acuerdo comercial sin descripción',
                    tooltipTitle: 'Acuerdo comercial:',
                },
                dynamicForm: {
                    title: '',
                },
                expenses: {
                    title: '',
                },
            },
        },
        'new-flow': {
            placeholderName: '',
            labelName: '',
            errorName: '',
            placeholderFlow: '',
            errorFlow: '',
            labelFlow: '',
            placeholderActivity: '',
            errorActivity: '',
            labelActivity: '',
            placeholderCase: '',
            labelCase: '',
            placeholderTeam: '',
            labelTeam: '',
            errorTeam: '',
            placeholderResponsible: '',
            labelResponsible: '',
            errorResponsible: '',
            placeholderTitle: '',
            labelTitle: '',
            errorTitle: '',
            placeholderDescription: '',
            labelDescription: '',
            buttonCompleteForm: '',
        },
        calendar: {
            title: '',
            subTitle: '',
        },
    },
    settlement: {
        auditedByLeader: {
            general: {},
            detail: {
                dateLabel: '',
                client: '',
                numberOfActivities: '',
                project: '',
                billableHours: '',
                changeClient: '',
            },
        },
        awaitingPreInvoice: {
            general: {
                draftPreinvoice: '',
            },
            detail: {
                presentation: {
                    title: '',
                    misterLabel: '',
                    letter: '',
                },
                information: {
                    title: '',
                    labels: {
                        endDate: '',
                        project: '',
                        phone: '',
                        accountingDocument: '',
                        settle: '',
                        tradeAgreement: '',
                        billIn: '',
                        client: '',
                        direction: '',
                        exchange: '',
                        rateAvarage: '',
                        subTotal: '',
                        total: '',
                    },
                },
                support: {
                    title: '',
                    addProyect: '',
                },
                remember: {
                    title: '',
                    toLeader: '',
                    toClient: '',
                },
                texts: {
                    noCategory: '',
                    noWorkflow: '',
                    activity: '',
                    workflow: '',
                    project: '',
                },
            },
        },
    },
    popUpComponent: {
        shareActivity: {
            Title: '',
            Subtitle: '',
        },
        changeCurrency: {
            Title: '',
            Subtitle: '',
            TypeFacture: '',
        },
        BillNotes: {
            Title: '',
            // ModificationLabel: '',
            // NameLabel: '',
            // DateLabel: '',
            // DescriptionLabel:
            //     '',
            // statusLabel: '',
            addNoteTitle: '',
            reference: '',
            labelDescription: '',
            placeholderDescription: '',
            errorDescription: '',
            labelCash: '',
            placeholderCash: '',
            labelCurrency: '',
            placeholderCurrency: '',
            labelValue: '',
            placehollder: '',
            cashHour: '',
            cashFlat: '',
            state: {
                title: '',
                Created: '',
                Opened: '',
                Closed: '',
            },
        },
        editProject: {
            title: '',
            labelClient: '',
            placeholderClient: '',
            errorClient: '',
            labelProject: '',
            placeholderProject: '',
            errorProject: '',
        },
        typesString: {
            billdingNote: '',
        },
        reasignTemplate: {
            title: 'REASIGN TO:',
            buttons: {
                cancel: 'Cancel',
                delete: 'Delete selection',
                apply: 'Save',
            },
        },
        preinvoiceComment: {
            header: 'Añadir comentario a prefactura',
            addComment: 'Añadir comentario',
        },
    },
    sideTabComponent: {
        client: {
            title: '',
            name: '',
            comercialName: '',
            nit: '',
            direction: '',
            phone: '',
            contacts: '',
        },
        project: {
            name: '',
            area: '',
            subArea: '',
            responsable: '',
            workGroup: '',
        },
        showCompleteInfo: '',
        history: {
            emptyState: '',
            property: '',
            oldValue: '',
            newValue: '',
        },
    },
    lateralHistoryComponent: {
        action: {
            labelMessage: '',
            placeholderMessage: '',
            errorMessage: '',
            labelTypeMessage: '',
            placeholderTypeMessage: '',
            errorTypeMessage: '',
            formats: '',
            messageCreate: '',
            messageErrorActivity: '',
        },
        styleLine: {
            typeHoursAdd: '',
            typeHoursDel: '',
            typeMinutesAdd: '',
            typeMinutesDel: '',
            connectEndLine: '',
            reOpenActivity: '',
            downloadActivity: '',
            changeField: '',
            oldValueChange: '',
            newValueChange: '',
            asignedData: '',
            asignedDataField: '',
            closeSpan: '',
        },
    },
    popUpWorksComponent: {
        labelEmptyState: '',
        action: {
            messageUpdate: '',
            messageError: '',
        },
    },
    reports: {
        labelLoader: '',
    },
};
