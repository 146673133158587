<template>
    <div class="home">
        <SideMenu />
        <HeaderComponent />
        <div class="content-container" style="position: relative">
            <router-view v-slot="{ Component }">
                <Transition name="fade" mode="out-in">
                    <component :is="Component" />
                </Transition>
            </router-view>
        </div>
    </div>
    <Teleport to="body">
        <ChronometerComponent
            :absolute="true"
            v-show="chronometerActivity != null" />
    </Teleport>
    <Teleport to="body">
        <CustomPopup
            v-if="showCustomPopup"
            :templateShow="templateCustomPopup"
            :additionalParams="paramsCustomPopup" />
    </Teleport>
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    import { mapState, mapActions } from 'vuex';
    export default {
        async created() {
            let getVersion = localStorage.getItem('version');
            if ((localStorage.getItem('localDataRefreshed') == null || localStorage.getItem('localDataRefreshed') == undefined) && process.env.VUE_APP_LOGOUT == 'true'){
                this.$store.commit(
                    'global/setTemplateCustomPopup',
                    'resetLocalData',
                );
                this.$store.commit('global/setShowCustomPopup', true);
            }
            else if (
                process.env.VUE_APP_VERSION !== getVersion ||
                getVersion === undefined ||
                getVersion === null
            ) {
                this.$store.commit('global/setParamsCustomPopup', {
                    headerImg: 'header-update-lucy.png',
                    update: true,
                });
                this.$store.commit('global/setShowCustomPopup', true);
                this.$store.commit(
                    'global/setTemplateCustomPopup',
                    'verifyFrom',
                );
            } else {
                //la versión es igual
                let version = localStorage.getItem('issaveversion');
                localStorage.setItem('issaveversion', true);
            }
            this.preCarga();
            if (this.status !== 'authenticated' && !this.loading) {
                this.$auth0.loginWithRedirect();
            }
            this.setUser();
            let localTenant = this.tenantId
                ? this.tenantId
                : localStorage.getItem('tenantId');
            await this.getToken(this.$auth0);
            if (localTenant === undefined || localTenant === null) {
                await this.getTenantsByUser(this.user.email);
                if (this.tenantsByUser.length === 0) {
                    this.$router.push({ name: 'register' });
                } else if (this.tenantsByUser.length === 1) {
                    localStorage.setItem('tenantId', this.tenantsByUser[0].id);
                    this.$store.commit(
                        'auth/setTenantId',
                        this.tenantsByUser[0].id,
                    );
                    await this.getGeneralInfoUSer();
                    let infoCompanyLocal = localStorage.getItem('company');
                    if (
                        infoCompanyLocal === null ||
                        infoCompanyLocal === undefined
                    ) {
                        this.getGeneralInfo();
                    }
                    this.$store.commit(
                        'global/updateStateLoading',
                        this.$auth0.isLoading.value,
                    );
                } else {
                    this.$router.push({ name: 'select-tenant' });
                }
            } else {
                await this.getGeneralInfoUSer();
                let infoCompanyLocal = localStorage.getItem('company');
                if (
                    infoCompanyLocal === null ||
                    infoCompanyLocal === undefined
                ) {
                    this.getGeneralInfo();
                }
                this.$store.commit(
                    'global/updateStateLoading',
                    this.$auth0.isLoading.value,
                );

                this.$store.commit('auth/setTenantId', parseInt(localTenant));
            }
        },
        data() {
            return {
                tokenDecode: null,
                showRoutes: false,
            };
        },
        components: {
            HeaderComponent: defineAsyncComponent(() =>
                import('../components/header/HeaderComponent.vue'),
            ),
            SideMenu: defineAsyncComponent(() =>
                import('../components/side-menu/SideMenu.vue'),
            ),
            CustomDropdown: defineAsyncComponent(() =>
                import('@/components/custom-dropdown/CustomDropdown.vue'),
            ),
            ChronometerComponent: defineAsyncComponent(() =>
                import('@/components/chronometer/ChronometerComponent.vue'),
            ),
            CustomPopup: defineAsyncComponent(() =>
                import('@/components/popup-custom/CustomPopup.vue'),
            ),
        },
        computed: {
            ...mapState('auth', ['status', 'user', 'token', 'tenantId']),
            ...mapState('global', [
                'loading',
                'tenantsByUser',
                'companyInfo',
                'showCustomPopup',
                'templateCustomPopup',
                'paramsCustomPopup',
                'chronometerActivity',
                'verifyDeploy',
                'changeCompany',
            ]),
            ...mapState('dashboard', ['teams', 'areas', 'razonesSocial']),
        },
        methods: {
            ...mapActions('auth', ['getToken']),
            ...mapActions('management', ['getRolesById']),
            ...mapActions('global', [
                'getOrganizationsByEmailUser',
                'getGeneralInfoByEmail',
                'getCompaniesByEmail',
                'getGeneralInfo',
                'getTenantsByUser',
                'getCountries',
            ]),
            async preCarga() {
                if (this.token != null) {
                    this.showRoutes = true;
                } else {
                    setTimeout(() => {
                        this.preCarga();
                    }, 5000);
                }
            },
            setUser() {
                this.$store.commit('auth/setUser', [
                    this.$auth0.user,
                    this.$auth0.isAuthenticated,
                    this.$auth0.error,
                ]);
            },
            async getGeneralInfoUSer() {
                let multipleCompanies = await this.getCompaniesByEmail(
                    this.user.email,
                );
                if (multipleCompanies.length > 1) {
                    if (!this.changeCompany) {
                        let selectedCompany = multipleCompanies.find(
                            (company) => company.isMain,
                        );
                        selectedCompany =
                            selectedCompany ?? multipleCompanies[0];
                        this.$store.commit(
                            'auth/setUserDBId',
                            selectedCompany.id,
                        );
                        this.$store.commit(
                            'auth/setUserId',
                            selectedCompany.id,
                        );
                        this.$store.commit(
                            'management/setCompanyActiveId',
                            selectedCompany.companyId,
                        );
                        localStorage.setItem(
                            'companyActiveName',
                            selectedCompany.companyName,
                        );
                        this.$store.commit(
                            'management/setCompanyActiveName',
                            selectedCompany.companyName,
                        );
                        localStorage.setItem(
                            'companyProcess',
                            selectedCompany.includeProcess,
                        );
                        this.$store.commit(
                            'management/setCompanyProcess',
                            selectedCompany.includeProcess,
                        );
                        localStorage.setItem('key', selectedCompany.id);
                        localStorage.setItem('utemp', selectedCompany.id);
                        localStorage.setItem('changeCompany', false);
                        this.$store.commit('global/setChangeCompany', false);
                    }
                } else {
                    if (!this.changeCompany) {
                        let selectedCompany = multipleCompanies[0];
                        this.$store.commit(
                            'auth/setUserDBId',
                            selectedCompany.id,
                        );
                        this.$store.commit(
                            'auth/setUserId',
                            selectedCompany.id,
                        );
                        this.$store.commit(
                            'management/setCompanyActiveId',
                            selectedCompany.companyId,
                        );
                        localStorage.setItem(
                            'companyActiveName',
                            selectedCompany.companyName,
                        );
                        this.$store.commit(
                            'management/setCompanyActiveName',
                            selectedCompany.companyName,
                        );
                        localStorage.setItem(
                            'companyProcess',
                            selectedCompany.includeProcess,
                        );
                        this.$store.commit(
                            'management/setCompanyProcess',
                            selectedCompany.includeProcess,
                        );
                        localStorage.setItem('key', selectedCompany.id);
                        localStorage.setItem('utemp', selectedCompany.id);
                        localStorage.setItem('changeCompany', false);
                        this.$store.commit('global/setChangeCompany', false);
                    }
                }
                let response = await this.getGeneralInfoByEmail(
                    this.user.email,
                );
                if (response.success === true) {
                    if (response.data !== null && response.data !== undefined) {
                        localStorage.setItem('key', response.data.userId);
                        localStorage.setItem('utemp', response.data.userId);
                        let getIds = localStorage.getItem(
                            'userIdViewAsPrincipal',
                        );
                        if (getIds === response.data.userId) {
                            this.$store.commit(
                                'auth/setUserId',
                                response.data.userId,
                            );
                        }
                        this.$store.commit('auth/setUserDB', {
                            userId: response.data.userId,
                            username: response.data.username,
                            supervisor: response.data.supervisor,
                            workgroup: response.data.workgroup,
                            meWorkgroups: response.data.meWorkgroups,
                            category: response.data.category,
                            picture: response.data.picture,
                            workingEdition: response.data.workingEdition,
                            subAreas: response.data.subAreas,
                            areas: response.data.areas,
                            isSuperAdmin: response.data.isSuperAdmin,
                        });
                        this.$store.commit('global/setCompanyInfo', {
                            companyId: response.data.companyId,
                            companyName: response.data.companyName,
                            companyProcess: response.data.includeProcess,
                            preselectResponsableUser: response.data.preselectResponsableUser,
                            typeBillableTime: response.data.typeBillableTime,
                            endDayDeliveryTime:
                                response.data.endDayDeliveryTime,
                            dailyWorkedHours: response.data.dailyWorkedHours,
                            workingDays: response.data.workingDays,
                            dailyHourRegistrationTimeLimit:
                                response.data.dailyHourRegistrationTimeLimit,
                            initialWorkHour: response.data.initialWorkHour,
                        });
                        if (response.data.rol) {
                            await this.getRolesById(response.data.rol);
                        }
                        let localCompanyId = localStorage.getItem('companyId');
                        if (
                            localCompanyId === null ||
                            localCompanyId !== response.data.companyId
                        ) {
                            localStorage.setItem(
                                'companyId',
                                response.data.companyId,
                            );
                        } else {
                            localStorage.setItem('companyId', localCompanyId);
                        }
                    }
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '../styles/styles.scss';
</style>
