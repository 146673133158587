import permissionsManagement from '@/router/auth-guard';
export default {
    name: 'business-partners',
    component: () => import(/* webpackChunkName: "business-partners" */ '@/modules/business-partners/layouts/BusinessPartnersLayout.vue'),
    children: [
        {
            path: '/grid-clients',
            name: 'client-dashboard',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'ClientFullAccess'
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/business-partners/views/dashboardClients.vue'
                ),

        },
        {
            path: '/client-info',
            name: 'client-info',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'ClientFullAccess'
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/business-partners/views/clientForm.vue'
                ),

        },
        {
            path: '/contact-info',
            name: 'contact-info',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'ClientFullAccess'
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/business-partners/views/contactForm.vue'
                ),
        },
        {
            path: '/partners-dashboard',
            name: 'partners-dashboard',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'ClientFullAccess'
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/business-partners/views/businessPartnersMenu.vue'
                ),
        },
        {
            path: '/partners-grid',
            name: 'partners-grid',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'ClientFullAccess'
                ]
            },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/business-partners/views/businessPartnersGrid.vue'
                ),
        },
    ]
}