export default {
    name: '',
    lastName: '',
    email: '',
    associatedCompany: '',
    subAreas: [],
    permissions: [],
    category: '00000000-0000-0000-0000-000000000000',
    jobAssignment: {
        workingDays: [],
    },
    notificationsParam: [],
    roleHierarchy: '',
    isSuperAdmin: true,
    active: true,
    picture: '',
    timeOuts: [],
    tenantId: 123,
    isContact: false,
    isPrincipal_Con: false,
    position_Con: '',
    dnI_Con: '',
    country_Con: '',
    city_Con: '',
    phoneNumber_Con: [],
    postalCode_Con: '',
    comments_Con: '',
    type_Con: 0,
    systemUser: true
};
