export default {
    name: '',
    main: false,
    generalInfo: {
        phone: '',
        address: '',
        country: '',
        city: '',
        contactEmail: [],
        uniqueIdentificationNumber: '',
        styleCompany: {
            logo: '',
            mainColor: '',
            secondaryColor: '',
        },
    },
    notifications:[],
    fields: [],
    companyInformation: {
        timeZone: '',
        language: '',
        dailyWorkedHours: 0,
        dailyBilledHours: 0,
        typeRestrictionActivityTime: 0,
        endDayDeliveryTime: null,
        typeBillableTime: 0,
        chanceRange: {
            firstEndHour: 0,
            secondEndHour: 0,
        },
        dailyHourRegistrationTimeLimit: '0',
        workingDays: [],
        holidays: [],
        initialWorkHour: '0',
    },
    active: true,
};
