<template>
    <div>
        Pagina no encontrada
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>