export default () => ({
    stateMenu: false,
    grid: null,
    withFilters: false,
    activities: {},
    configSchedulerViews: [],
    dataDashboard: null,
    metaRangeByUser: 0,
    cargandobtn: 1,
    /*dayIndicators: [{
        "TiempoFacturable": 120,
        "TiempoFacturablePercent": 70,
        "TiempoNoFacturable": 50,
        "TiempoNoFacturablePercent": 30,
        "Eficiencia": 0.85,
        "historicoOportunidad": {
            "bien": 60,
            "mal": 40
        },
        "LimiteHoras": 8,
        "ActividadesAsignadasHoras": {
            "Fecha": "Viernes 10 Junio - 2022",
            "TiempoAsignado": 6
        },
        "ActividadesAsignadasHorasDescargadas": {
            "Fecha": "Viernes 10 Junio - 2022",
            "TiempoDescargado": 4
        }
    }, ],
    weekIndicators: [{
        "TiempoFacturable": 32,
        "TiempoNoFacturable": 8,
        "Eficiencia": 0.8,
        "historicoOportunidad": {
            "bien": 0.9,
            "mal": 0.1
        },
        "ActividadesAsignadasHoras": {
            "Fecha": "del 6 al 12 de Junio - 2022",
            "LimiteHoras": 8,
            "TotalHoras": 34,
            "dias": [{
                    "dia": "Lunes",
                    "TiempoAsignado": 6
                },
                {
                    "dia": "Martes",
                    "TiempoAsignado": 8
                },
                {
                    "dia": "Miercoles",
                    "TiempoAsignado": 5
                },
                {
                    "dia": "Jueves",
                    "TiempoAsignado": 8
                },
                {
                    "dia": "Viernes",
                    "TiempoAsignado": 7
                }

            ]

        },
        "ActividadesAsignadasHorasDescargadas": {
            "Fecha": "del 6 al 12 de Junio - 2022",
            "LimiteHoras": 8,
            "TotalHoras": 29,
            "dias": [{
                    "dia": "Lunes",
                    "TiempoDescargado": 6
                },
                {
                    "dia": "Martes",
                    "TiempoDescargado": 8
                },
                {
                    "dia": "Miercoles",
                    "TiempoDescargado": 5
                },
                {
                    "dia": "Jueves",
                    "TiempoDescargado": 8
                },
                {
                    "dia": "Viernes",
                    "TiempoDescargado": 2
                }

            ]
        }
    }],
    monthIndicators: [{
        "TiempoFacturable": 130,
        "TiempoNoFacturable": 14,
        "Eficiencia": 0.3,
        "historicoOportunidad": {
            "bien": 0.50,
            "mal": 0.50
        },
        "ActividadesAsignadasHoras": {
            "Fecha": "Junio - 2022",
            "TotalHoras": 104,
            "Semanas": [{
                    "semana": "1",
                    "LimiteHoras": 40,
                    "TiempoAsignado": 34
                },
                {
                    "semana": "2",
                    "LimiteHoras": 40,
                    "TiempoAsignado": 40
                },
                {
                    "semana": "3",
                    "LimiteHoras": 32,
                    "TiempoAsignado": 20
                },
                {
                    "semana": "4",
                    "LimiteHoras": 32,
                    "TiempoAsignado": 10
                }
            ]
        },
        "ActividadesAsignadasHorasDescargadas": {
            "Fecha": "Junio - 2022",
            "TotalHoras": 29,
            "Semanas": [{
                    "semana": "1",
                    "LimiteHoras": 40,
                    "TiempoDescargado": 29
                },
                {
                    "semana": "2",
                    "LimiteHoras": 40,
                    "TiempoDescargado": 0
                },
                {
                    "semana": "3",
                    "LimiteHoras": 32,
                    "TiempoDescargado": 0
                },
                {
                    "semana": "4",
                    "LimiteHoras": 32,
                    "TiempoDescargado": 0
                }
            ]
        }
    }],
    yearIndicators: [{
        "TiempoFacturable": 130,
        "TiempoNoFacturable": 14,
        "Eficiencia": 0.6,
        "historicoOportunidad": {
            "bien": 0.60,
            "mal": 0.30
        },
        "ActividadesAsignadasHoras": {
            "Fecha": "de Enero a Diciembre 2022",
            "TotalHoras": 1027,
            "Meses": [{
                    "mes": "Enero",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 160
                },
                {
                    "mes": "Febrero",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 160
                },
                {
                    "mes": "Marzo",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 160
                },
                {
                    "mes": "Abril",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 160
                },
                {
                    "mes": "Mayo",
                    "LimiteHoras": 152,
                    "TiempoAsignado": 146
                },
                {
                    "mes": "Junio",
                    "LimiteHoras": 144,
                    "TiempoAsignado": 104
                },
                {
                    "mes": "Julio",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 10
                },
                {
                    "mes": "Agosto",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 22
                },
                {
                    "mes": "Septiembre",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 30
                },
                {
                    "mes": "Octubre",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 50
                },
                {
                    "mes": "Noviembre",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 10
                },
                {
                    "mes": "Diciembre",
                    "LimiteHoras": 160,
                    "TiempoAsignado": 15
                }
            ]
        },
        "ActividadesAsignadasHorasDescargadas": {
            "Fecha": "de Enero a Diciembre 2022",
            "TotalHoras": 775,
            "Meses": [{
                    "mes": "Enero",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 160
                },
                {
                    "mes": "Febrero",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 160
                },
                {
                    "mes": "Marzo",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 160
                },
                {
                    "mes": "Abril",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 160
                },
                {
                    "mes": "Mayo",
                    "LimiteHoras": 152,
                    "TiempoDescargado": 106
                },
                {
                    "mes": "Junio",
                    "LimiteHoras": 144,
                    "TiempoDescargado": 29
                },
                {
                    "mes": "Julio",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                },
                {
                    "mes": "Agosto",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                },
                {
                    "mes": "Septiembre",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                },
                {
                    "mes": "Octubre",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                },
                {
                    "mes": "Noviembre",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                },
                {
                    "mes": "Diciembre",
                    "LimiteHoras": 160,
                    "TiempoDescargado": 0
                }
            ]
        }
    }],*/
    listWorkFlows: [],
    listActivities: [],
    listCases: [],
    filterState: 4,
    generalFilterDash: JSON.parse(
        localStorage.getItem('transversalFilter'),
    ) ?? {
        unitservice: [],
        practices: [],
        can: [],
        oportunityStates: 4,
        page: 0,
        pageSize: 0,
        states: [],
        time: 4,
        completeActs: true,
        clientIds: [],
        subAreasIds: [],
        usersIds: [],
        onlyUsersIds: [],
        workgroup: [],
        company: localStorage.getItem('companyId'),
        groups: [],
        subareas: [],
        supervisor: [],
        starDate: new Date(),
        endDate: new Date(),
        typeTime: 1,
        Options: {
            requireTotalCount: true,
            searchOperation: 'contains',
            searchValue: null,
            skip: 0,
            take: 20,
            userData: {},
            sort: null,
            group: null,
            totalSummary: [],
        },
        clientTimeZone: null,
    },
    selectedTeam: [],
    selectedTeamCount: 0,
    teamsOnlyItems: [],
    selectedArea: [],
    selectedAreaCount: 0,
    areasOnlyItems: [],
    selectedCustomer: [],
    selectedCustomerCount: 0,
    customersOnlyItems: [],
    selectedTime: localStorage.getItem('selectedTime') ?? 'Mes',
    selectedDate: localStorage.getItem('selectedDate') ?? new Date(),
    teams: JSON.parse(localStorage.getItem('teams')) ?? [],
    areas: JSON.parse(localStorage.getItem('areas')) ?? [],
    razonesSocial: JSON.parse(localStorage.getItem('razonesSocial')) ?? [],
    totalSearchActivities: null,
    timeBillableIndication: 0,
    timeNoBillableIndication: 0,
    historyOportunityIndication: 0,
    assigmentHoursIndication: 0,
    workedsHoursIndication: 0,
    awaitingRevisionHoursIndication: 0,
    efficiencyIndication: 0,
    completeActivitiesIndication: 0,
    anualBillableIndication: 0,
    loadingIndicators: [true, true, true, true, true, true, true, true, true],
    currentGridTitle: '',
    clearFilterGeneral: false,
    GCselectedTeam: [],
    GCselectedTeamCount: 0,
    GCteamsOnlyItems: [],
    GCselectedRZ: [],
    GCselectedRZCount: 0,
    GCRZOnlyItems: [],
    timesByUsersData: [],
    filterExportActivities: {},
    totalSearchActivitiesInRevision: null,
    SelectedDateToNumber: 0,
    SelectedTypeDateFilter: 3, //MES
    SelectedDateStartIndicator: 0,
    SelectedDateEndIndicator: 0,
});
