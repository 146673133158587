import permissionsManagement from '@/router/auth-guard';

export default {
    name: 'management',
    component: () =>
        import(
            /* webpackChunkName: "management" */
            '@/modules/management/layouts/ManagementLayout.vue'
        ),
    children: [
        {
            path: 'company',
            name: 'company',
            component: () =>
                import(
                    /* webpackChunkName: "company" */
                    '@/modules/management/layouts/CompanyLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-company',
                    name: 'dash-company',
                    redirect: () => {
                        return {
                            name: 'general',
                        };
                    },
                },
                {
                    path: 'general',
                    name: 'general',
                    beforeEnter: permissionsManagement,
                    meta: {
                        permissions: [
                            'AdministrationCompany',
                            'AdministrationUsers',
                            'AdministrationClient',
                            'AdministrationProjects',
                            'AdministrationfunctionalStructure',
                        ],
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "company-general" */
                            '@/modules/management/modules/company/general/GeneralView.vue'
                        ),
                },
                {
                    path: 'config-system',
                    name: 'config-system',
                    component: () =>
                        import(
                            /* webpackChunkName: "company-general" */
                            '@/modules/management/modules/company/ConfigSystem.vue'
                        ),
                },
                {
                    path: 'add-company',
                    name: 'add-company',
                    beforeEnter: permissionsManagement,
                    meta: {
                        permissions: [
                            'AdministrationCompanyGeneralCreate',
                            'AdministrationCompanyGeneralUpdate',
                        ],
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "add-company" */
                            '@/modules/management/modules/company/general/AddCompany.vue'
                        ),
                },
                {
                    path: 'add-currency',
                    name: 'add-currency',
                    component: () =>
                        import(
                            '@/modules/management/modules/company/Currency/GeneralCurrency.vue'
                        ),
                },
                {
                    path: 'add-indexing',
                    name: 'add-indexing',
                    component: () =>
                        import(
                            '@/modules/management/modules/company/IndexinUnit/AddIndexing.vue'
                        ),
                },
                {
                    path: 'add-GeneralIindexingUnit',
                    name: 'add-GeneralIindexingUnit',
                    component: () =>
                        import(
                            '@/modules/management/modules/company/IndexinUnit/GeneralIindexingUnit.vue'
                        ),
                },
                {
                    path: 'company-notifications',
                    name: 'company-notifications',
                    component: () =>
                        import(
                            /* webpackChunkName: "company-notifications" */
                            '@/modules/management/modules/company/NotificationsView.vue'
                        ),
                },
                {
                    path: 'rates',
                    name: 'rates',
                    component: () =>
                        import('@/modules/management/layouts/RatesLayout.vue'),
                    children: [
                        {
                            path: '/rates',
                            name: 'dash-rates',
                            component: () =>
                                import(
                                    '@/modules/management/modules/tariff/Tariff-view.vue'
                                ),
                        },
                        {
                            path: '/rate-book',
                            name: 'rate-book',
                            component: () =>
                                import(
                                    '@/modules/management/modules/tariff/RateBooks/RateBookComponent.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'file',
                    name: 'file',
                    component: () =>
                        import(
                            '@/modules/management/modules/company/PruebaFIle.vue'
                        ),
                },
                {
                    path: 'APIs',
                    name: 'APIs',
                    component: () =>
                        import(
                            '@/modules/management/modules/company/PruebaFIle.vue'
                        ),
                },
            ],
        },
        {
            path: 'functional-structure',
            name: 'functional-structure',
            component: () =>
                import(
                    '@/modules/management/layouts/FunctionalStructureLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-functional-structure',
                    name: 'dash-functional-structure',
                    redirect: () => {
                        return {
                            name: 'practice',
                        };
                    },
                },
                {
                    path: 'add-practice',
                    name: 'add-practice',
                    component: () =>
                        import(
                            '@/modules/management/modules/functional-structure/Practice/addPractice.vue'
                        ),
                },
                {
                    path: 'practice',
                    name: 'practice',
                    component: () =>
                        import(
                            /* webpackChunkName: "practice" */
                            '@/modules/management/modules/functional-structure/Practice/PracticeView.vue'
                        ),
                },
                {
                    path: 'service-units',
                    name: 'service-units',
                    component: () =>
                        import(
                            /* webpackChunkName: "service-units" */
                            '@/modules/management/modules/functional-structure/ServiceUnit/ServiceUnitsView.vue'
                        ),
                },
                {
                    path: 'add-serviceunits',
                    name: 'add-serviceunits',
                    component: () =>
                        import(
                            /* webpackChunkName: "service-units" */
                            '@/modules/management/modules/functional-structure/ServiceUnit/add-ServiceUnit.vue'
                        ),
                },
                {
                    path: 'activities',
                    name: 'activities',
                    component: () =>
                        import(
                            /* webpackChunkName: "activities" */
                            '@/modules/management/modules/functional-structure/activities/ActivitiesView.vue'
                        ),
                },
                {
                    path: 'activities/type-activity',
                    name: 'type-activity',
                    component: () =>
                        import(
                            /* webpackChunkName: "type-activity" */
                            '@/modules/management/modules/functional-structure/activities/TypeActivity.vue'
                        ),
                },
                {
                    path: 'activities/add-more-fields',
                    name: 'add-more-fields',
                    component: () =>
                        import(
                            /* webpackChunkName: "add-more-fields" */
                            '@/modules/management/modules/functional-structure/activities/AddMoreFields.vue'
                        ),
                },
                {
                    path: 'flows',
                    name: 'module-flows',
                    component: () =>
                        import(
                            /* webpackChunkName: "flows" */
                            '@/modules/management/layouts/functional-structure/FlowsLayout.vue'
                        ),
                    children: [
                        {
                            path: 'dash-flows',
                            name: 'flows',
                            component: () =>
                                import(
                                    /* webpackChunkName: "flows" */
                                    '@/modules/management/modules/functional-structure/flows/DashboardComponent.vue'
                                ),
                        },
                        {
                            path: 'flows',
                            redirect: () => {
                                return {
                                    name: 'dash-flows',
                                };
                            },
                        },
                        {
                            path: 'general',
                            name: 'general-flows',
                            component: () =>
                                import(
                                    /* webpackChunkName: "flows" */
                                    '@/modules/management/modules/functional-structure/flows/GeneralComponent.vue'
                                ),
                        },
                        {
                            path: 'alerts',
                            name: 'alerts-flows',
                            component: () =>
                                import(
                                    /* webpackChunkName: "flows" */
                                    '@/modules/management/modules/functional-structure/flows/AlertsComponent.vue'
                                ),
                        },
                        {
                            path: 'new-alert',
                            name: 'create-alert',
                            component: () =>
                                import(
                                    /* webpackChunkName: "flows" */
                                    '@/modules/management/modules/functional-structure/flows/GeneralAlerts.vue'
                                ),
                        },
                        {
                            path: 'add-more-fields-flow-form',
                            name: 'add-more-fields-flow-form',
                            component: () =>
                                import(
                                    /* webpackChunkName: "add-more-fields-flow-form" */
                                    '@/modules/management/modules/functional-structure/flows/AddMoreFields.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'dynamic-forms',
                    name: 'module-dynamic-forms',
                    component: () =>
                        import(
                            /* webpackChunkName: "dynamic-forms" */
                            '@/modules/management/layouts/functional-structure/DynamicsFormsLayout.vue'
                        ),
                    children: [
                        {
                            path: 'dash-dynamic-forms',
                            name: 'dynamic-forms',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dynamic-forms" */
                                    '@/modules/management/modules/functional-structure/dynamic-forms/DashboardComponent.vue'
                                ),
                        },
                        {
                            path: 'dynamic-forms/general-info',
                            name: 'general-form',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dynamic-forms" */
                                    '@/modules/management/modules/functional-structure/dynamic-forms/GeneralInfoComponent.vue'
                                ),
                        },
                        {
                            path: 'dynamic-forms/form-design',
                            name: 'form-design',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dynamic-forms" */
                                    '@/modules/management/modules/functional-structure/dynamic-forms/DesignForm.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'activities',
                    redirect: () => {
                        return {
                            name: 'practice',
                        };
                    },
                },
            ],
        },
        {
            path: 'users',
            name: 'users',
            component: () =>
                import(
                    /* webpackChunkName: "users" */
                    '@/modules/management/layouts/UsersLayout.vue'
                ),
            children: [
                {
                    path: 'users',
                    name: 'module-users',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-users" */
                            '@/modules/management/layouts/users/UsersLayout.vue'
                        ),
                    children: [
                        {
                            path: 'dash-users',
                            name: 'dash-users',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-users" */
                                    '@/modules/management/modules/users/users/DashboardUsers.vue'
                                ),
                        },
                        {
                            path: 'general-info',
                            name: 'general-info-users',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-users" */
                                    '@/modules/management/modules/users/users/GeneralInfoUsers.vue'
                                ),
                        },
                        {
                            path: 'job-profile',
                            name: 'job-profile',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-users" */
                                    '@/modules/management/modules/users/users/JobProfile.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'work-groups',
                    name: 'module-work-groups',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-users" */
                            '@/modules/management/layouts/users/WorkGroupsLayout.vue'
                        ),
                    children: [
                        {
                            path: 'dash-work-groups',
                            name: 'dash-work-groups',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-users" */
                                    '@/modules/management/modules/users/work-groups/DashboardWorkGroups.vue'
                                ),
                        },
                        {
                            path: 'general-info',
                            name: 'general-info-work-group',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-users" */
                                    '@/modules/management/modules/users/work-groups/GeneralInfoWorkGroups.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'roles',
                    name: 'dash-roles',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-users" */
                            '@/modules/management/modules/users/roles/DashboardRoles.vue'
                        ),
                },
                {
                    path: 'roles/general-info',
                    name: 'general-info-roles',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-users" */
                            '@/modules/management/modules/users/roles/GeneralInfoRoles.vue'
                        ),
                },
                {
                    path: 'dash-users',
                    redirect: () => {
                        return {
                            name: 'dash-users',
                        };
                    },
                },
            ],
        },
        {
            path: 'customers',
            name: 'customers',
            component: () =>
                import(
                    /* webpackChunkName: "customers" */
                    '@/modules/management/layouts/CustomersLayout.vue'
                ),
            children: [
                {
                    path: 'dash-customers',
                    name: 'dash-customers',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-customers" */
                            '@/modules/management/modules/customers/customers/CustomersView.vue'
                        ),
                },
                {
                    path: 'create-customer',
                    name: 'customer-visualizer',
                    component: () =>
                        import(
                            /* webpackChunkName: "create-customer" */
                            '@/modules/management/modules/customers/customers/CreateCustomerView.vue'
                        ),
                },
                {
                    path: 'contacts',
                    name: 'dash-contacts',
                    component: () =>
                        import(
                            /* webpackChunkName: "create-customer" */
                            '@/modules/management/modules/customers/contacts/ContactsView.vue'
                        ),
                },
                {
                    path: 'contact-visualizer',
                    name: 'contact-visualizer',
                    component: () =>
                        import(
                            /* webpackChunkName: "create-customer" */
                            '@/modules/management/modules/customers/contacts/CreateContactView.vue'
                        ),
                },
                {
                    path: 'dashboard-customers',
                    redirect: () => {
                        return {
                            name: 'dash-customers',
                        };
                    },
                },
            ],
        },
        {
            path: 'projects',
            name: 'projects',
            component: () =>
                import(
                    /* webpackChunkName: "projects" */
                    '@/modules/management/layouts/ProjectsLayout.vue'
                ),
            children: [
                {
                    path: 'module-projects',
                    name: 'module-projects',
                    component: () =>
                        import(
                            /* webpackChunkName: "projects" */
                            '@/modules/management/layouts/projects/ProjectsLayoutChildren.vue'
                        ),
                    children: [
                        {
                            path: 'dashboard-projects',
                            name: 'dash-projects',
                            component: () =>
                                import(
                                    /* webpackChunkName: "projects" */
                                    '@/modules/management/modules/projects/ProjectsDashboard.vue'
                                ),
                        },
                        {
                            path: 'projects',
                            redirect: () => {
                                return {
                                    name: 'dash-projects',
                                };
                            },
                        },
                        {
                            path: 'create-project',
                            name: 'create-projects',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/GeneralView.vue'
                                ),
                        },
                        {
                            path: 'members-privacy',
                            name: 'members-privacy',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/MembersView.vue'
                                ),
                        },
                        {
                            path: 'trade-agreement',
                            name: 'trade-agreement',
                            component: () =>
                                import(
                                    '@/modules/management/modules/projects/trade-agreement/TradeAgreementView.vue'
                                ),
                        },
                        {
                            path: 'commercial-agreement',
                            name: 'commercial-agreement',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/trade-agreement/CreateTradeAgreement.vue'
                                ),
                        },
                        {
                            path: 'activities',
                            name: 'dash-project-activities',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/DashActivities.vue'
                                ),
                        },
                        {
                            path: 'notifications',
                            name: 'notifications-trade',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/trade-agreement/NotificationsTrade.vue'
                                ),
                        },
                        {
                            path: 'bills',
                            name: 'dash-bills',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/bills/DashBills.vue'
                                ),
                        },
                        {
                            path: 'new-bill',
                            name: 'create-bill',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/bills/CreateBill.vue'
                                ),
                        },
                        {
                            path: 'project-billing',
                            name: 'projects-billing',
                            component: () =>
                                import(
                                    /* webpackChunkName: "dashboard-projects" */
                                    '@/modules/management/modules/projects/ProjectsAccordionBilling.vue'
                                ),
                        },
                    ],
                },
                {
                    path: 'projects',
                    redirect: () => {
                        return {
                            name: 'dash-projects',
                        };
                    },
                },
            ],
        },
        {
            path: 'customer-portal',
            name: 'customer-portal',
            component: () =>
                import(
                    /* webpackChunkName: "customer-portal" */
                    '@/modules/management/layouts/CustomersPortalLayout.vue'
                ),
            children: [
                {
                    path: 'dashboard-customer-portal',
                    name: 'dash-customer-portal',
                    component: () =>
                        import(
                            /* webpackChunkName: "dashboard-customer-portal" */
                            '@/modules/management/views/CustomerPortalView.vue'
                        ),
                },
                {
                    path: 'dashboard-customer-portal',
                    redirect: () => {
                        return {
                            name: 'dash-customer-portal',
                        };
                    },
                },
            ],
        },
    ],
};
