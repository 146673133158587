import { logger } from './logger';

export const loggerMixin = {
  methods: {
    $log(log, locate) {
      logger.log(log, locate);
    },
    $getLogs(locate) {
      return logger.getLogs(locate);
    },
    $clearLogs(locate) {
      logger.clearLogs(locate);
    }
  }
};