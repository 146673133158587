export default {
    name: 'documental',
    component: () =>
        import(
            /* webpackChunkName: "documentation-center" */
            '@/modules/documentation-center/layouts/DocumentationCenterLayout.vue'
        ),
    children: [
        {
            path: 'documentation-center',
            name: 'documentation-center',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Centro de documentación', url: 'documentation-center' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "company" */
                    '@/modules/documentation-center/modules/Documental/DocumentalView.vue'
                ),
        },
    ],
};
