import axios from 'axios';
import store from '@/store/index';
import { connections_confg } from '@/utils/connection_config';

const organizationApi = axios.create({
    baseURL: connections_confg.baseURLManagement,
    timeout: 420000,
});

const waitForToken = () => {
    return new Promise((resolve) => {
        const checkToken = () => {
            if (store.state.auth.token) {
                resolve(store.state.auth.token);
            } else {
                setTimeout(checkToken, 100);
            }
        };
        checkToken();
    });
};

organizationApi.interceptors.request.use(async (config) => {
    const token = await waitForToken();
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; chartset=UTF-8',
    };
    return config;
});

export default organizationApi;
