export default {
	name: 'auth',
	component: () =>
		import(
			/* webpackChunkName: "auth" */ '@/modules/auth/layouts/AuthLayout.vue'
		),
	children: [
		{
			path: '',
			name: 'postlogin',
			component: () =>
				import(
					/* webpackChunkName: "login" */ '@/modules/auth/views/LogIn.vue'
				),
		},
		{
			path: 'select-tenant',
			name: 'select-tenant',
			component: () =>
				import(
					/* webpackChunkName: "selecttenant" */ '@/modules/auth/views/SelectTenant.vue'
				),
		},
		{
			path: '/need-help',
			name: 'need-help',
			component: () =>
				import(
					/* webpackChunkName: "register" */ '@/modules/auth/views/NeedHelp.vue'
				),
		},
	],
};
