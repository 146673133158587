<template>
    <div>
        <label for="languageToggle">{{ $t('message.hello') }}</label>
        <input
            id="languageToggle"
            v-model="isEnglish"
            type="checkbox"
            @change="toggleLanguage" />
        <span>{{ isEnglish ? 'English' : 'Spanish' }}</span>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isEnglish: true,
            };
        },
        methods: {
            toggleLanguage() {
                // Implement logic to toggle language
                const newLocale = this.isEnglish ? 'en' : 'es';
                this.$i18n.locale = newLocale;
            },
        },
    };
</script>

<style scoped lang="scss">
    /* Add component-specific styles here */
</style>
