export default {
    companyId: '', // GUID
    name: '', // STRING,
    businessName: '', //STRING
    typeIdentification: '', // STRING
    uniqueIdentificationNumber: '', // STRING
    bondingDate: '', // DATE,
    enterpriseGroupId: '', // GUID
    loyalty: '', // GUID
    state: 1, // INT,
    country: '', // STRING
    mainCity: '', // STRING
    address: [],
    email: '', // STRING
    phone: [],
    postalCode: '', // STRING
    isNaturalPerson: true, // BOOL
    contacts: [
        {
            id: '', // GUID
            isPrincipal: true, // BOOL
            name: '', // STRING
            email: '', // STRING
            numberOfContact: [],
            position: '', // STRING
            type: 0, // ENTERO
        },
    ],
    fields: [
        {
            label: '', // STRING
            typeControl: '', // STRING
            value: '', // STRING
            mandatory: '', // STRING
        },
    ],  
    active: true,
};
