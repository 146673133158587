<template>
    <div>
        <Teleport to="body" v-if="showVideo !== 'hide'">
            <video
                style="
                    position: fixed;
                    top: 0;
                    margin: auto;
                    z-index: 400;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: #e1e1fe;
                "
                autoplay
                muted
                id="splash">
                <source src="@/assets/Lucy_Logo.mp4" />
            </video>
        </Teleport>
        <div v-else>
            <div v-if="loading">
                <LoadingSpinner />
            </div>
            <router-view />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    import { mapState, mapActions } from 'vuex';
    export default {
        data() {
            return {
                showVideo: '',
            };
        },
        computed: {
            ...mapState('global', ['loading']),
        },
        components: {
            LoadingSpinner: defineAsyncComponent(
                () =>
                    import(
                        '../src/components/loading-spinner/LoadingSpinner.vue'
                    ),
            ),
        },
        created() {
            this.initializeVideoState();
        },
        mounted() {
            this.setupVideoEndHandler();
        },
        methods: {
            ...mapActions('dashboard', ['cancelDashboardRequests']),
            initializeVideoState() {
                if (!localStorage.getItem('initialStateModified')) {
                    localStorage.setItem('initialStateModified', false);
                }
                this.showVideo = localStorage.getItem('showVideo') || '';
            },
            setupVideoEndHandler() {
                const videoElement = document.getElementById('splash');
                if (videoElement) {
                    videoElement.onended = this.handleVideoEnd;
                }
            },
            handleVideoEnd() {
                localStorage.setItem('showVideo', 'hide');
                this.showVideo = 'hide';
            },
        },
        watch: {
            async $route(to, from) {
                if (from.name === 'info-cards') {
                    await this.cancelDashboardRequests();
                }
            },
        },
    };
</script>
