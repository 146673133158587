import store from '@/store/index';
const permissionsManagement = async (to, from, next) => {
    let permissions, autorized, autorizedLocal, isSuperAdmin;
    autorizedLocal = JSON.parse(localStorage.getItem('autorized'));
    return new Promise((resolve) => {
        permissions = store._state.data.auth.permissions;
        isSuperAdmin = store._state.data.auth.userDB.isSuperAdmin;
        autorized = to.meta.permissions.some((permisoModulo) =>
            permissions.some((permiso) => permiso.includes(permisoModulo)),
        );
        isSuperAdmin || autorized
            ? resolve(true)
            : resolve(false);
    }).then((response) => {
        if (response) {
            next();
            localStorage.setItem('autorized', true);
        } else {
            next({ name: 'info-cards' });
            localStorage.setItem('autorized', false);
        }
    });
};

export default permissionsManagement;
