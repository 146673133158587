<template>
  <div>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState } from 'vuex'

export default {
  components: {
		LoadingSpinner: defineAsyncComponent(() =>
			import("../components/loading-spinner/LoadingSpinner.vue")
		),
  },
  computed: {
    ...mapState('auth', ['status', 'user', 'token', 'tenantId']),
		...mapState('global', ['loading']),
  },
  created() {
    if (this.status !== 'authenticated' && !this.loading) {
			this.$auth0.loginWithRedirect();
		} else {
      this.setUser()
    }
  },
  data() {
    return {

    }
  },
  methods: {
    setUser() {
      this.$store.commit("auth/setUser", [
				this.$auth0.user,
				this.$auth0.isAuthenticated,
				this.$auth0.error,
			]);
			this.$store.commit("global/updateStateLoading", this.$auth0.isLoading.value);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/styles.scss';
</style>