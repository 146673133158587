export const setUser = (state, info) => {
    const [user, statusAuth, errors] = info;
    state.user = user.value;
    state.status =
        statusAuth.value === true ? 'authenticated' : 'isNotAuthenticated';
    state.error = errors.value;
};

export const setToken = (state, token) => {
    state.token = token;
};

export const setCompanyVars = (state, decodeToken) => {
    const [token] = decodeToken;
    state.tenantId = state.tenantId
        ? state.tenantId
        : token['https://godoycordoba-customclaims.com/tenantId'];
    state.permissions = [
        token['https://godoycordoba-customclaims.com/SIAC-DEV'],
    ];
};

export const createTenantId = (state, company) => {
    const { data } = company;
    state.tenantId = data.tenantID;
};

export const setTenantId = (state, tenantId) => {
    state.tenantId = tenantId;
};

export const setUserDB = (state, info) => {
    state.userDB = info;
    localStorage.setItem('picture', state.userDB.picture);
};
export const setUserDBPermissions = (state, info) => {
    state.permissions = info;
};

export const setUserDBId = (state, id) => {
    state.userDB.id = id;
    localStorage.setItem('picture', state.userDB.picture);
};

export const setUserId = (state, id) => {
    state.userIdViewAsPrincipal = id;
    localStorage.setItem('userIdViewAsPrincipal', id);
};
