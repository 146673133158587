export const GetDocuments = (state, Documents) => {
    state.Documents = Documents;
};
export const setSiacClients = (state, clients) => {
    state.clientsSiac = clients;
};
export const setSiacUsers = (state, Documents) => {
    state.usersSiac = Documents;
};
export const setFilterClients = (state, Documents) => {
    state.filterClients = Documents;
};
export const setFilterWorkgroup = (state, Documents) => {
    state.filterWorkgroups = Documents;
};

export const setChangeLocalDocumentalCenter = (state, stateLocal) => {
    state.changeLocalDocumentalCenter = stateLocal;
};

export const setLocal = (state, local) => {
    state.local = local;
};