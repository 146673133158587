export default {
    name: 'settlement-billing-client',
    component: () =>
        import(
            /* webpackChunkName: "settlement-billing" */ '@/modules/settlement-billing/layouts/SettlementBillingLayoutClient.vue'
        ),
    children: [
        {
            path: 'preinvoice-client/detail/:companyId/:preinvoiceId',
            name: 'detail-preinvoice-client',
            component: () =>
                import(
                    '@/modules/settlement-billing/views/PreinvoiceClient/PreinvoiceDetailClient.vue'
                ),
        },
    ],
};
