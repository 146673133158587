export default () => ({
    status: '', // status=true -> authenticated-status=false -> isNotAuthenticated-loading=true -> authenticating
    user: null,
    token: null,
    error: null,
    tenantId: undefined,
    permissions: [],
    userDB: {
        userId: null,
        userName: null,
        supervisor: null,
        workgroup: null,
        category: null,
        picture: null,
        isSuperAdmin: null,
    },
    userIdViewAsPrincipal: localStorage.getItem('userIdViewAsPrincipal') ?? '',
});
