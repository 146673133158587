import permissionsManagement from '@/router/auth-guard';
export default {
    name: 'settlement-billing',
    component: () =>
        import(
            /* webpackChunkName: "settlement-billing" */ '@/modules/settlement-billing/layouts/SettlementBillingLayout.vue'
        ),
    children: [
        {
            path: 'audited-by-leader',
            name: 'audited-by-leader',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementAccessReviewedServicesAll',
                    'SettlementAccessReviewedServicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Servicios revisados', url: 'audited-by-leader' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "audited-by-leader" */ '@/modules/settlement-billing/views/AuditedByLeader/AuditedByLeader.vue'
                ),
        },
        {
            path: 'audited-by-leader/detail',
            name: 'detail-audited-by-leader',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementAccessReviewedServicesAll',
                    'SettlementAccessReviewedServicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Servicios revisados', url: 'audited-by-leader' },
                    { name: 'Detalle servicio', url: 'detail-audited-by-leader' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "audited-by-leader-detail" */ '@/modules/settlement-billing/views/AuditedByLeader/AuditedByLeaderDetail.vue'
                ),
        },
        {
            path: 'awaiting-preinvoice',
            name: 'awaiting-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPendingPreinvoicesAll',
                    'SettlementReadPendingPreinvoicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Pendiente prefacturas', url: 'awaiting-preinvoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "awaiting-preinvoice" */ '@/modules/settlement-billing/views/AwaitingPreinvoice/AwaitingPreinvoice.vue'
                ),
        },
        {
            path: 'awaiting-preinvoice/detail',
            name: 'detail-awaiting-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPendingPreinvoicesAll',
                    'SettlementReadPendingPreinvoicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Pendiente prefacturas', url: 'awaiting-preinvoice' },
                    { name: 'Detalle pendiente prefactura', url: 'detail-awaiting-preinvoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "awaiting-preinvoice-detail" */ '@/modules/settlement-billing/views/AwaitingPreinvoice/AwaitingPreinvoiceDetail.vue'
                ),
        },
        {
            path: 'preinvoice',
            name: 'preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPreinvoicesAll',
                    'SettlementReadPreinvoicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Prefacturas', url: 'preinvoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "preinvoice" */ '@/modules/settlement-billing/views/PreInvoice/Preinvoice-component.vue'
                ),
        },
        {
            path: 'preinvoice/detail',
            name: 'detail-preinvoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadPreinvoicesAll',
                    'SettlementReadPreinvoicesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Prefacturas', url: 'preinvoice' },
                    { name: 'Detalle prefactura', url: 'detail-preinvoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "detail-preinvoice" */ '@/modules/settlement-billing/views/PreInvoice/PreinvoiceDetail.vue'
                ),
        },
        {
            path: 'invoice',
            name: 'invoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadLiquidatedAll',
                    'SettlementReadLiquidatedWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturacion', url: '' },
                    { name: 'Liquidadas', url: 'invoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "invoice" */ '@/modules/settlement-billing/views/Invoice/InvoiceComponent.vue'
                ),
        },
        {
            path: 'invoice/detail',
            name: 'detail-invoice',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadLiquidatedAll',
                    'SettlementReadLiquidatedWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturacion', url: '' },
                    { name: 'Liquidadas', url: 'invoice' },
                    { name: 'Detalle liquidada', url: 'detail-invoice' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "detail-invoice" */ '@/modules/settlement-billing/views/Invoice/InvoiceDetail.vue'
                ),
        },
        {
            path: 'specialActivation',
            name: 'specialActivation',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementReadFutureBlocksAll',
                    'SettlementReadFutureBlocksWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Liquidación', url: '' },
                    { name: 'Bloques futuros', url: 'specialActivation' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "special-activation" */ '@/modules/settlement-billing/views/SearchBlockForSpecialActivation/SearchBloksForSpecialActivation.vue'
                ),
        },
        {
            path: 'creditNotes',
            name: 'creditNotes',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementCreditNotesAll',
                    'SettlementCreditNotesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturación', url: '' },
                    { name: 'Notas credito', url: 'creditNotes' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "credit-notes" */ '@/modules/settlement-billing/views/CreditNotes/CreditNotes.vue'
                ),
        },
        {
            path: 'creditNotes/detail',
            name: 'view-credit-note',
            beforeEnter: permissionsManagement,
            meta: {
                permissions: [
                    'SettlementAccessAll',
                    'SettlementAccessWorkgroup',
                    'SettlementCreditNotesAll',
                    'SettlementCreditNotesWorkgroup',
                ],
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturación', url: '' },
                    { name: 'Notas credito', url: 'creditNotes' },
                    { name: 'Detalle nota credito', url: 'view-credit-note' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "credit-note-detail" */ '@/modules/settlement-billing/views/CreditNotes/CreditNotesDetail.vue'
                ),
        },
        {
            path: 'cash-register-receipt',
            name: 'cash-register-receipt',
            meta: {
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturación', url: '' },
                    { name: 'Recibos de caja', url: 'cash-register-receipt' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "cash-register-receipt" */ '@/modules/settlement-billing/views/CashRegisterReceipt/CashRegisterReceipt.vue'
                ),
        },
        {
            path: 'cash-register-receipt-detail',
            name: 'cash-register-receipt-detail',
            meta: {
                father: 'settlement',
                breadcrumb: [
                    { name: 'Facturación', url: '' },
                    { name: 'Recibos de caja', url: 'cash-register-receipt' },
                    { name: 'Detalle recibo de caja', url: 'cash-register-receipt-detail' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "cash-register-receipt-detail" */ '@/modules/settlement-billing/views/CashRegisterReceipt/CashRegisterReceiptDetail.vue'
                ),
        },
    ],
};
