import permissions from './auth-guard';
export default {
    name: 'dashboard',
    component: () =>
        import(
            /* webpackChunkName: "dashboard" */ '@/modules/dashboard/layouts/DashboardLayout.vue'
        ),
    children: [
        {
            path: '',
            name: 'info-cards',
            // meta: {
            //     breadcrumb: [{ name: 'Inicio', url: 'info-cards' }],
            // },
            component: () =>
                import(
                    /* webpackChunkName: "info-cards" */ '@/modules/dashboard/views/CardsView.vue'
                ),
        },
        {
            path: 'grid-activities',
            name: 'grid-activities',
            props: true,
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Actividades en curso', url: 'grid-activities' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridView.vue'
                ),
        },
        {
            path: 'grid-activities-complete',
            name: 'grid-activities-complete',
            props: true,
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    {
                        name: 'Actividades completadas',
                        url: 'grid-activities-complete',
                    },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewComplete.vue'
                ),
        },
        {
            path: 'grid-activities-migrated',
            name: 'grid-activities-migrated',
            props: true,
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    {
                        name: 'Actividades migradas',
                        url: 'grid-activities-migrated',
                    },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewMigrated.vue'
                ),
        },
        {
            path: 'grid-activities-created',
            name: 'grid-activities-created',
            props: true,
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    {
                        name: 'Actividades sin asignar',
                        url: 'grid-activities-created',
                    },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewOnlyCreate.vue'
                ),
        },
        {
            path: 'view-calendar',
            name: 'view-calendar',
            props: true,
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Agenda', url: 'view-calendar' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/CalendarView.vue'
                ),
        },
        {
            path: 'grid-workflows',
            name: 'grid-workflows',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Flujos en curso', url: 'grid-workflows' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewWorkFlow.vue'
                ),
        },
        {
            path: 'grid-workflows-close',
            name: 'grid-workflows-close',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    {
                        name: 'Tabla de flujos cerrados',
                        url: 'grid-workflows-close',
                    },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewWorkFlowClose.vue'
                ),
        },
        {
            path: 'grid-cases',
            name: 'grid-cases',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Casos en curso', url: 'grid-cases' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewCases.vue'
                ),
        },
        {
            path: 'grid-cases-close',
            name: 'grid-cases-close',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Casos cerrados', url: 'grid-cases' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-activities" */ '@/modules/dashboard/views/GridViewCasesClosed.vue'
                ),
        },
        {
            path: 'grid-revision',
            name: 'grid-revision',
            meta: {
                breadcrumb: [
                    { name: 'Inicio', url: 'info-cards' },
                    { name: 'Grid Revisión', url: 'grid-revision' },
                ],
            },
            component: () =>
                import(
                    /* webpackChunkName: "grid-revision" */ '@/modules/dashboard/views/GridViewRevision.vue'
                ),
        },
    ],
};
